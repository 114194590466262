import OrganizerDetailPage from '@/pages/organizer/slug';
import type { RouteObject } from 'react-router-dom';

export const organizerRoutes: RouteObject[] = [
  {
    path: 'organizer/:slug',
    element: <OrganizerDetailPage />,
  },
  {
    path: 'organizers/:slug',
    element: <OrganizerDetailPage />,
  },
];
