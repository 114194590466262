import { useEffect, useMemo } from 'react';
import {
  Button,
  Checkbox,
  Dropzone,
  Input,
  MobileInput,
  ResponsiveMenu,
  TextArea,
  ToggleGroup,
  Typography,
} from '@wbk/ui';
import { COUNTRIES, EMAIL_PATTERN, scrollToTop } from '@wbk/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGeolocation, useRouter } from '@wbk/config';
import { FingerHeart } from '@wbk/svg';
import { useDeviceDetect } from '@wbk/hooks';
import { VULNERABILITY_TYPES } from '@/constants/security';

interface FormProps {
  full_name: string;
  email: string;
  phoneCountry: (typeof COUNTRIES)[number];
  phone: string;
  vulnerability_type: string[];
  vulnerability_description: string;
  vulnerability_replicate: string;
  vulnerability_potential_risk: string;
  vulnerability_severity: string;
  images: File[];
  what_happened: string;
  what_should_happened: string;
  agreement: boolean;
  loading?: boolean;
  submitted?: boolean;
}

const SecurityForm = () => {
  const { t } = useTranslation(['common', 'security', 'auth_common']);
  const {
    control,
    setValue,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useFormContext<FormProps>();
  const { locale } = useRouter();
  const { country: detectedCountry } = useGeolocation();
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    if (detectedCountry) {
      setValue('phoneCountry', detectedCountry, { shouldValidate: true });
    }
  }, [detectedCountry, setValue]);

  const phoneCountry = watch('phoneCountry');
  const loading = watch('loading');
  const submitted = watch('submitted');

  const selectedCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === phoneCountry?.code);
  }, [phoneCountry?.code]);

  const onSubmit = async (data: FormProps) => {
    setValue('loading', true);

    const formData = new FormData();

    const exclude = ['phoneCountry', 'phone', 'agreement', 'submitted', 'loading'];

    formData.append('phone_number', `${data.phoneCountry?.dial_code}${data.phone}`);

    Object.entries(data).forEach(([key, value]) => {
      if (exclude.includes(key)) return;

      if (key === 'vulnerability_type' && Array.isArray(value)) {
        formData.append('vulnerability_type', value.join(', '));
      } else if (Array.isArray(value)) {
        value.forEach((v) => formData.append(key, v));
      } else {
        formData.append(key, value);
      }
    });

    try {
      await fetch('https://formbucket.com/f/buk_9mV5t50oUGRpu83zXqsBDeyf', {
        method: 'POST',
        body: formData,
      });
      setValue('submitted', true);
      scrollToTop();
    } finally {
      setValue('loading', false);
    }
  };

  if (submitted) {
    return (
      <div className='lg:border-paper w-full max-w-xl space-y-6 lg:rounded-xl lg:border lg:p-6'>
        <FingerHeart />
        <Typography variant='heading-XL'>{t('security:form.thanks_for_submission')}</Typography>
        <Typography variant='body-L'>{t('security:form.appreciate_efforts')}</Typography>
        <Typography variant='body-L'>{t('security:form.further_information')}</Typography>
        <Button
          className='hidden lg:flex'
          type='button'
          theme='white'
          shape='outlined'
          onClick={() => {
            setValue('submitted', false);
            reset();
          }}
        >
          {t('security:form.submit_another_report')}
        </Button>
      </div>
    );
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id='security-form'
      encType='multipart/form-data'
      className='lg:border-paper space-y-5 rounded-xl lg:border lg:p-6'
    >
      <Typography className='hidden lg:flex' variant='heading-M'>
        {t('security:form.form_header')}
      </Typography>
      <Typography variant='heading-XS'>{t('security:form.reporter_info_title')}</Typography>
      <Controller
        name='full_name'
        control={control}
        rules={{
          required: t('common:validation.required'),
          maxLength: {
            value: 50,
            message: t('common:validation.max_length', { length: 50 }),
          },
        }}
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <Input
            {...props}
            label={t('security:form.name')}
            placeholder={t('security:form.name_placeholder')}
            id='full_name'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name='phone'
        control={control}
        key={phoneCountry?.code}
        rules={{
          required: t('auth_common:validation.required'),
          validate: (value) => {
            const hasValidation = phoneCountry?.mobile_starts_with?.length;
            if (value.includes(' ')) {
              return t('auth_common:validation.invalid_mobile');
            }
            if (hasValidation) {
              const validStart = phoneCountry?.mobile_starts_with?.some((start) =>
                value.startsWith(start)
              );
              const validLength = value.length >= Number(phoneCountry?.phone_number_lengths?.[0]);
              return (validStart && validLength) || t('auth_common:validation.invalid_mobile');
            }
          },
        }}
        render={({ field: { value, ...props } }) => (
          <MobileInput
            {...props}
            label={t('security:form.phone')}
            country={phoneCountry}
            mobile={value || ''}
            portal={!isMobile}
            onChange={(name, value) => {
              if (name === 'country') {
                setValue('phoneCountry', value as (typeof COUNTRIES)[number]);
              } else {
                setValue('phone', value as string);
              }
            }}
            error={errors?.phone?.message || errors?.phoneCountry?.message}
            placeholder={
              selectedCountry
                ? `${selectedCountry?.mobile_starts_with?.[0] || ''}${'x'.repeat(
                    (selectedCountry.phone_number_lengths?.[0] || 7) - 1
                  )}`
                : ''
            }
          />
        )}
      />

      <Controller
        control={control}
        name='email'
        rules={{
          required: t('common:validation.required'),
          pattern: {
            value: EMAIL_PATTERN,
            message: t('common:validation.invalid_email'),
          },
        }}
        defaultValue=''
        render={({ field: { onChange, ...props } }) => (
          <Input
            id='email'
            autoComplete='off'
            type='email'
            label={t('security:form.email')}
            placeholder={t('common:auth.enter_email_address')}
            {...props}
            onChange={(value) => {
              onChange(value);
            }}
            error={errors.email?.message}
          />
        )}
      />
      <Typography variant='heading-XS'>{t('security:form.vulnerability_title')}</Typography>

      <Typography variant='label-M' className='!-mb-4'>
        {t('security:form.vulnerability_type')}
      </Typography>
      <Controller
        rules={{ required: t('common:validation.required') }}
        control={control}
        name='vulnerability_type'
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <ResponsiveMenu
            options={VULNERABILITY_TYPES.map((value) => ({
              value,
              text: t(`security:form.vulnerability_type_${value}`),
            }))}
            multiple
            {...props}
            sheet={{ ignoreHistory: true }}
            onChange={(options) => {
              onChange(options.map((option) => option.value));
            }}
            trigger={{
              className:
                'border-paper w-full max-w-full overflow-hidden border py-3 text-start md:max-w-2xl [&>span]:grow',
            }}
            error={error?.message}
          />
        )}
      />
      <Controller
        name='vulnerability_description'
        control={control}
        rules={{
          required: t('common:validation.required'),
          maxLength: {
            value: 500,
            message: t('common:validation.max_length', { length: 500 }),
          },
        }}
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <TextArea
            {...props}
            label={t('security:form.vulnerability_describe')}
            placeholder={t('security:form.vulnerability_describe_placeholder')}
            id='vulnerability_description'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name='vulnerability_replicate'
        control={control}
        rules={{
          required: t('common:validation.required'),
          maxLength: {
            value: 500,
            message: t('common:validation.max_length', { length: 500 }),
          },
        }}
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <TextArea
            {...props}
            label={t('security:form.vulnerability_reproduce')}
            placeholder={t('security:form.vulnerability_reproduce_placeholder')}
            id='vulnerability_replicate'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name='vulnerability_potential_risk'
        control={control}
        rules={{
          required: t('common:validation.required'),
          maxLength: {
            value: 500,
            message: t('common:validation.max_length', { length: 500 }),
          },
        }}
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <Input
            {...props}
            label={t('security:form.vulnerability_impact')}
            placeholder={t('security:form.vulnerability_impact_placeholder')}
            id='vulnerability_potential_risk'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />
      <Controller
        rules={{ required: t('common:validation.required') }}
        control={control}
        name='vulnerability_severity'
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <ToggleGroup
            type='single'
            phill
            activeBtnProps={{ shape: 'outlined', theme: 'white' }}
            btnProps={{ className: 'border-paper' }}
            groupClassName='block space-x-2 space-y-2'
            {...props}
            onChange={onChange}
            label={t('security:form.vulnerability_severity')}
            error={errors?.vulnerability_severity?.message || error?.message || ''}
            options={[
              {
                value: 'critical',
                text: t('security:form.vulnerability_severity_critical'),
              },
              {
                value: 'high',
                text: t('security:form.vulnerability_severity_high'),
              },
              {
                value: 'medium',
                text: t('security:form.vulnerability_severity_medium'),
              },
              {
                value: 'low',
                text: t('security:form.vulnerability_severity_low'),
              },
              {
                value: 'informational',
                text: t('security:form.vulnerability_severity_informational'),
              },
            ]}
          />
        )}
      />

      <Typography variant='heading-XS'>{t('security:form.uplaod_document_title')}</Typography>
      <Controller
        rules={{ required: t('common:validation.required') }}
        control={control}
        name='images'
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <Dropzone
            {...props}
            onChange={onChange}
            helperText={t('security:form.uplaod_document_description')}
            label={t('security:form.uplaod_document_subtitle')}
            error={errors?.images?.message ? String(errors?.images?.message) : error?.message || ''}
            multiple
            maxSize={5}
          />
        )}
      />

      <Typography variant='heading-XS'>{t('security:form.more_details_title')}</Typography>
      <Controller
        name='what_happened'
        control={control}
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <Input
            {...props}
            label={t('security:form.where_vulnerability')}
            placeholder={t('security:form.where_vulnerability_placeholder')}
            id='what_happened'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name='what_should_happened'
        control={control}
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <TextArea
            {...props}
            label={t('security:form.correct_behaviour')}
            placeholder={t('security:form.correct_behaviour_placeholder')}
            id='what_should_happened'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name='agreement'
        control={control}
        rules={{
          required: t('common:validation.required'),
        }}
        render={({ field: { onChange, value, ...props }, fieldState: { error } }) => (
          <Checkbox
            {...props}
            checked={value || false}
            label={t('security:form.agreement')}
            id='agreement'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />

      <Button
        theme='primary'
        type='submit'
        className='hidden lg:flex'
        disabled={loading}
        loading={loading}
      >
        {t('common:submit')}
      </Button>
      <Typography variant='body-S' className='!mt-2 max-w-80' color='text-secondary'>
        <Trans i18nKey='security:form.terms_and_conditions'>
          <Button
            theme='transparent'
            shape='text'
            className='text-text-secondary p-0 underline'
            as={Link}
            to={`/${locale}/terms`}
          ></Button>
          <Button
            theme='transparent'
            shape='text'
            className='text-text-secondary p-0 underline'
            as={Link}
            to={`/${locale}/privacy`}
          ></Button>
        </Trans>
      </Typography>
    </form>
  );
};
export default SecurityForm;
