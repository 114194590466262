import { useTranslation } from 'react-i18next';
import { Button, Menu, Typography } from '@wbk/ui';
import { ChevronIcon } from '@wbk/svg';
import useFilters from '@/context/filters/useSearch';

const sortList = [
  { text: 'most_popular', value: '' },
  { text: 'latest', value: 'latest' },
  { text: 'oldest', value: 'oldest' },
  { text: 'price_low_to_high', value: 'low_price' },
  { text: 'price_high_to_low', value: 'high_price' },
];

type Props = {
  totalEvents: number;
};

const ExploreSortFilter = ({ totalEvents }: Props) => {
  const { t } = useTranslation('common');
  const { sort, selectFilter } = useFilters();

  if (totalEvents <= 0) return null;

  return (
    <div className='flex shrink-0 items-center gap-1'>
      <p>{t('common:result_and_sort', { total: totalEvents })}</p>
      <Menu
        render={({ open, selected }) => (
          <Button theme='action' shape='text' className='p-px' onClick={open}>
            <Typography variant='link-M' strong>
              {selected?.text}
            </Typography>
            <ChevronIcon className='h-3 w-3 shrink-0 rotate-90' />
          </Button>
        )}
        value={sort || ''}
        onChange={(selected) => {
          selectFilter({ key: 'sort', value: selected.value });
        }}
        options={sortList.map((op) => ({
          text: t(op.text),
          value: op.value,
        }))}
        hideErrorSpace
      />
    </div>
  );
};

export default ExploreSortFilter;
