import { useGetTags } from '@wbk/contentful/api';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import { useDeviceDetect } from '@wbk/hooks';
import { CloseIcon } from '@wbk/svg';
import { useAnalytics } from '@wbk/analytics';
import useLocalization from '@/context/localization/useLocalization';
import useFilters from '@/context/filters/useSearch';

const ExploreTagsFilters = () => {
  const { locale, detectLoading } = useLocalization();
  const { t } = useTranslation('common');
  const { isMobile } = useDeviceDetect();
  const { tag, country, selectFilter } = useFilters();
  const { trackEvent } = useAnalytics();

  const { data, isFetching } = useGetTags(
    {
      lang: locale,
      where: { country: country ? { countryCode: country } : {} },
    },
    { enabled: !detectLoading }
  );

  if (isFetching) {
    return (
      <>
        <hr className='border-paper my-4' />
        <div className='space-y-3 py-2.5'>
          <div className='bg-text/5 h-7 max-w-[60%] animate-pulse rounded-md'></div>
          <div className='space-y-3 overflow-hidden px-0.5'>
            {[1, 2].map((i) => (
              <div key={i} className='bg-text/5 h-7 animate-pulse rounded-md'></div>
            ))}
          </div>
        </div>
      </>
    );
  }

  if (!data?.length) {
    return null;
  }

  return (
    <>
      <hr className='border-paper my-4' />
      <div className='space-y-4'>
        <h2 className='text-2xl font-medium'>{t('common:tags')}</h2>
        <ul className='flex flex-wrap gap-1.5 text-sm'>
          {data.map((t) => {
            const selected = tag?.includes(t.slug);
            return (
              <li key={t.slug}>
                <Button
                  theme={selected ? 'primary' : 'white'}
                  shape={selected ? 'solid' : 'outlined'}
                  className={`px-3 py-1 ${selected ? '' : 'text-text-secondary border-paper'}`}
                  onClick={() => {
                    selectFilter({ key: 'tag', value: [t.slug], apply: !isMobile });
                    if (!selected) {
                      trackEvent({
                        event: 'clicked_on_tags',
                        data: { tag_slug: t.slug },
                      });
                    }
                  }}
                  phill
                >
                  <span>{t.title}</span>
                  {selected && <CloseIcon className='h-3 w-3 shrink-0' />}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ExploreTagsFilters;
