import { Input, RadioGroup, Select, TextArea, Typography } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { COUNTRIES } from '@wbk/utils';
import useLocalization from '@/context/localization/useLocalization';
import { BusinessFormValues } from '@/components/business/types';

const BusinessInfoFormItems = () => {
  const { locale } = useLocalization();
  const { t } = useTranslation(['business', 'common']);
  const { control, watch } = useFormContext<BusinessFormValues>();
  const [businessType] = watch(['business_type', 'country']);

  return (
    <div className='space-y-4'>
      <Typography variant='heading-S'>{t(`business:form.about_your_${businessType}`)}</Typography>

      <Controller
        control={control}
        name='business_name'
        defaultValue=''
        rules={{ required: t('common:validation.required') }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Input
              {...field}
              label={
                <Typography variant='label-M'>{t(`business:form.${businessType}_name`)}</Typography>
              }
              error={error?.message}
            />
          );
        }}
      />

      {businessType !== 'restaurant' && (
        <Controller
          control={control}
          name='date'
          defaultValue=''
          rules={{
            required: t('common:validation.required'),
            validate: {
              futureDate: (value) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const selectedDate = new Date(value);
                return selectedDate >= today || t('common:validation.invalid_date');
              },
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <Input
                type='date'
                {...field}
                label={
                  <Typography variant='label-M'>
                    {t(`business:form.${businessType}_date`)}
                  </Typography>
                }
                error={error?.message}
              />
            );
          }}
        />
      )}

      <div>
        <Controller
          control={control}
          name='country'
          defaultValue=''
          rules={{ required: t('common:validation.required') }}
          render={({ field: { onChange, ...props }, fieldState: { error } }) => {
            return (
              <Select
                {...props}
                error={error?.message}
                placeholder={t('business:form.country_placeholder')}
                label={
                  <Typography variant='label-M'>
                    {t(`business:form.${businessType}_location`)}
                  </Typography>
                }
                groupClassName='grow'
                onChange={(selected) => onChange(selected?.value || '')}
                options={COUNTRIES.map((c) => ({
                  id: c.code,
                  value: c.code,
                  text: c[`name_${locale}` as 'name'] || c.name,
                  icon: (
                    <img
                      className='shrink-0'
                      src={`https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${c.code.toLowerCase()}.png`}
                      width={20}
                      height={14}
                      alt={c.name}
                    />
                  ),
                }))}
              />
            );
          }}
        />
      </div>

      {businessType !== 'restaurant' ? (
        <Controller
          control={control}
          name='attendees_count'
          defaultValue=''
          rules={{ required: t('common:validation.required') }}
          render={({ field, fieldState: { error } }) => {
            return (
              <Input
                {...field}
                label={
                  <Typography variant='label-M'>{t('business:form.attendees_count')}</Typography>
                }
                placeholder={t('business:form.attendees_count_placeholder')}
                error={error?.message}
                type='number'
                min={1}
                onWheel={(e) => e.currentTarget.blur()}
              />
            );
          }}
        />
      ) : (
        <>
          <Controller
            control={control}
            name='date'
            rules={{
              required: t('common:validation.required'),
              validate: {
                futureDate: (value) => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  const selectedDate = new Date(value);
                  return selectedDate >= today || t('common:validation.invalid_date');
                },
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <Input
                  {...field}
                  label={
                    <Typography variant='label-M'>
                      {t('business:form.restaurant_live_date')}
                    </Typography>
                  }
                  error={error?.message}
                  type='date'
                />
              );
            }}
          />

          <Controller
            control={control}
            name='branches_count'
            rules={{ required: t('common:validation.required') }}
            render={({ field, fieldState: { error } }) => {
              return (
                <Input
                  {...field}
                  label={
                    <Typography variant='label-M'>{t('business:form.branches_count')}</Typography>
                  }
                  placeholder={t('business:form.enter_number')}
                  error={error?.message}
                  type='number'
                  min={1}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              );
            }}
          />

          <Controller
            control={control}
            name='online_booking_system'
            rules={{ required: t('common:validation.required') }}
            render={({ field, fieldState: { error } }) => {
              return (
                <RadioGroup
                  {...field}
                  error={error?.message}
                  groupClassName='flex items-stretch space-y-0 gap-4'
                  className='w-full flex-row-reverse border-none p-0'
                  label={
                    <Typography variant='label-M'>
                      {t('business:form.online_booking_system')}
                    </Typography>
                  }
                  options={[
                    {
                      text: t('business:form.yes'),
                      value: 'yes',
                    },
                    {
                      text: t('business:form.no'),
                      value: 'no',
                    },
                  ]}
                />
              );
            }}
          />
        </>
      )}

      <Controller
        control={control}
        name='description'
        render={({ field, fieldState: { error } }) => {
          return (
            <TextArea
              {...field}
              label={
                <Typography variant='label-M'>
                  {t(`business:form.${businessType}_description`)}
                </Typography>
              }
              placeholder={t(`business:form.${businessType}_description_placeholder`)}
              error={error?.message}
            />
          );
        }}
      />
      <Typography variant='heading-S'>{t('business:form.about_you')}</Typography>
      <Controller
        control={control}
        name='job_title'
        rules={{ required: t('common:validation.required') }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Input
              {...field}
              label={<Typography variant='label-M'>{t('business:form.job_title')}</Typography>}
              placeholder={t('business:form.enter_here')}
              error={error?.message}
            />
          );
        }}
      />
      <Controller
        control={control}
        name='entity_name'
        rules={{ required: t('common:validation.required') }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Input
              {...field}
              label={<Typography variant='label-M'>{t('business:form.entity_name')}</Typography>}
              placeholder={t('business:form.enter_here')}
              error={error?.message}
            />
          );
        }}
      />
    </div>
  );
};

export default BusinessInfoFormItems;
