import { Button, Input } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@wbk/svg';
import { useAnalytics } from '@wbk/analytics';
import SearchContent from '@/components/search/SearchContent';
import useSearch from '@/context/search/useSearch';
import useLocalization from '@/context/localization/useLocalization';
import SearchLocationFilter from './Location';

const MobileSearchContent = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { locale } = useLocalization();
  const { trackEvent } = useAnalytics();
  const { searchState, onSearch, togglePopover, country, city } = useSearch();

  return (
    <>
      <header className='bg-body sticky top-0 z-10 flex items-center gap-2 px-4 py-2 shadow-sm'>
        <form
          className='relative grow'
          onSubmit={(event) => {
            event.preventDefault();
            if (!searchState?.trim()) {
              return;
            }
            togglePopover(false);
            const params = new URLSearchParams();
            params.set('q', searchState || '');
            if (city) {
              params.set('city', city);
            }
            if (country) {
              params.set('country', country);
            }
            if (searchState?.trim()) {
              navigate(`/${locale}/search?${params.toString()}`);
            }
          }}
        >
          <Input
            data-testid='header_mobile_search_input'
            type='search'
            inputMode='search'
            className='!bg-body ltr:pl-9 rtl:pr-9'
            placeholder={t('common:search')}
            value={searchState || ''}
            onFocus={() =>
              trackEvent({
                event: 'clicked_on_search',
              })
            }
            onChange={(value) => onSearch(value)}
            startIcon={<SearchIcon className='mx-2 h-full w-6 shrink-0 opacity-70' />}
          />
        </form>
        <Button
          data-testid='header_mobile_close_search_button'
          shape='text'
          theme='white'
          onClick={() => togglePopover(false)}
          aria-label='Search'
          className='px-3'
        >
          {t('common:cancel')}
        </Button>
      </header>

      <div className='mb-20 max-h-[calc(100vh-60px)] space-y-4 overflow-y-auto p-4 pb-24'>
        <SearchLocationFilter />
        <SearchContent />
      </div>
    </>
  );
};

export default MobileSearchContent;
