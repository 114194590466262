import { Suspense, useEffect, useState } from 'react';
import { readCookie } from '@wbk/utils';
import { HideOnQueue } from '@wbk/queue';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { ChevronIcon, PersonRoundedIcon } from '@wbk/svg';
import UserMenu from './UserMenu';

const UserProfileButton = () => {
  const { lang } = useParams();
  const { pathname } = useLocation();
  const [hasCookie, setHasCookie] = useState(false);

  useEffect(() => {
    const token = readCookie('token');
    if (token) {
      setHasCookie(true);
    }
  }, []);

  return (
    <Suspense
      fallback={
        <div className='bg-body-level-1 mx-2 h-6 w-6 animate-pulse rounded-full md:h-9 md:w-9'></div>
      }
    >
      <HideOnQueue>
        {hasCookie ? (
          <UserMenu />
        ) : (
          <Button
            as={Link}
            to={`/${lang}/login?redirect=${pathname}`}
            theme='white'
            shape='text'
            data-testid='header_nav_login_button'
            data-location='header'
            className='flex w-full items-center p-0'
            role='menuitem'
          >
            <PersonRoundedIcon className='md:text-text-secondary h-6 w-6 md:h-9 md:w-9' />
            <ChevronIcon className='text-text-secondary mx-1 hidden h-2 w-2 rotate-90 md:block' />
          </Button>
        )}
      </HideOnQueue>
    </Suspense>
  );
};

export default UserProfileButton;
