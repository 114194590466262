import React from 'react';
import AppLayout from '@/App';
import type { RouteObject } from 'react-router-dom';
const PlanBookingPage = React.lazy(() => import('@/pages/subscriptions/book'));
const PlanUpgradePage = React.lazy(() => import('@/pages/subscriptions/upgrade'));
const PlansPage = React.lazy(() => import('@/pages/subscriptions'));
const ManageSubscriptionsPage = React.lazy(() => import('@/pages/subscriptions/manage'));

export const subscriptionRoutes: RouteObject[] = [
  {
    path: 'subscriptions',
    element: <AppLayout />,
    children: [
      {
        path: '',
        element: <PlansPage />,
      },
      {
        path: 'manage/:id',
        element: <ManageSubscriptionsPage />,
      },
    ],
  },
  {
    path: 'subscriptions',
    element: <AppLayout type='booking' />,
    children: [
      {
        path: 'book/:id',
        element: <PlanBookingPage />,
      },
      {
        path: 'upgrade/:id',
        element: <PlanUpgradePage />,
      },
    ],
  },
];
