import { useTranslation } from 'react-i18next';
import { Button, RadioGroup, ResponsivePopover, Typography } from '@wbk/ui';
import { WorldOutlinedIcon } from '@wbk/svg';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { CurrencySymbol, useCurrency } from '@wbk/currency';
import { languages } from '@/i18n/config';
import useLocalization from '@/context/localization/useLocalization';

type FormValues = { locale: string; currency: string; loading: boolean };

const LanguageCurrencySwitch = () => {
  const { t } = useTranslation('common');
  const methods = useForm<FormValues>();
  const loading = methods.watch('loading');

  return (
    <FormProvider {...methods}>
      <ResponsivePopover
        popover={{
          className: 'w-[420px] max-w-full',
          align: 'end',
          sideOffset: 14,
          cancel: false,
          action: {
            label: t('common:apply'),
            form: 'localization-selection-form',
            loading,
            className: 'px-10',
          },
        }}
        sheet={{
          ignoreHistory: true,
          cancel: false,
          action: {
            label: t('common:apply'),
            form: 'localization-selection-form',
            loading,
            className: 'px-10',
          },
        }}
        render={({ open }) => (
          <Button
            shape='text'
            theme='white'
            className='md:text-text-secondary divide-paper-level-1 items-center divide-x p-0 font-sans md:py-1 rtl:divide-x-reverse'
            onClick={open}
            data-testid='lang-switcher'
            role='menuitem'
          >
            <WorldOutlinedIcon className='h-6 w-6 md:h-4 md:w-4' />
            <div className='px-1'>
              <CurrencySymbol />
            </div>
          </Button>
        )}
        body={({ close }) => <Body close={close} />}
      />
    </FormProvider>
  );
};

const Body = ({ close }: { close: () => void }) => {
  const { locale, setLocale } = useLocalization();
  const { currency, currencies, setCurrency } = useCurrency();
  const { t } = useTranslation('common');
  const { control, handleSubmit, setValue } = useFormContext<FormValues>();

  const onSubmit = async (data: FormValues) => {
    try {
      setValue('loading', true);
      const currentLang = locale;

      setCurrency(data.currency);
      if (data.locale !== currentLang) {
        await setLocale(data.locale);
      }
      close();
    } catch (error) {
      // under investigation if really helpful or not
      // window.location.reload();
    } finally {
      setValue('loading', false);
    }
  };

  return (
    <div className='space-y-4'>
      <Typography variant='heading-L'>{t('common:nav.preferences')}</Typography>
      <form
        id='localization-selection-form'
        className='space-y-10'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name='locale'
          defaultValue={locale}
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              theme='action'
              indicator='check'
              label={
                <Typography className='mb-3' variant='heading-S'>
                  {t('common:nav.language')}
                </Typography>
              }
              groupClassName='grid gap-4 space-y-0 grid-cols-2'
              options={languages.map((lang) => ({ text: t(`common:${lang}`), value: lang }))}
            />
          )}
        />

        <Controller
          name='currency'
          defaultValue={currency.shortCode}
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              theme='action'
              indicator='check'
              label={
                <Typography className='mb-3' variant='heading-S'>
                  {t('common:nav.currency')}
                </Typography>
              }
              groupClassName='space-y-4'
              options={currencies.map((currency) => {
                const code = currency.shortCode;
                const countryCode = code.slice(0, 2);
                return {
                  text: (
                    <div className='flex items-center gap-4'>
                      <img
                        className='aspect-1 h-8 w-8 shrink-0 object-contain'
                        src={`https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${countryCode.toLowerCase()}.png`}
                        alt=''
                      />
                      <div>
                        <Typography variant='label-M'>{currency.name}</Typography>
                        <Typography variant='body-S' color='text-secondary'>
                          <CurrencySymbol currency={currency} />
                        </Typography>
                      </div>
                    </div>
                  ),
                  value: code,
                };
              })}
            />
          )}
        />
      </form>
    </div>
  );
};

export default LanguageCurrencySwitch;
