export const SAFF_SLUG = 'afc-elite-finals-2025';
export const ORGANIZATION_SAFF_SLUG = 'afc-elite-finals';

export const SAFF_CSS = `
#main, .prose {
  --color-primary-100: 40 30% 95%;
  --color-primary-200: 40 30% 85%;
  --color-primary-300: 40 30% 75%;
  --color-primary-400: 40 30% 65%;
  --color-primary-500: 40 30% 56%; 
  --color-primary-600: 40 30% 46%;
  --color-primary-700: 40 30% 36%;
  --color-primary-800: 40 30% 26%;
  --color-primary-900: 40 30% 18%;
  --color-primary-contrast: 0 0% 0%;

  --color-secondary-100: 240 5% 98%; 
  --color-secondary-200: 240 5% 95%;
  --color-secondary-300: 240 5% 90%;
  --color-secondary-400: 240 5% 85%;
  --color-secondary-500: 240 5% 96%;
  --color-secondary-600: 240 5% 75%;
  --color-secondary-700: 240 5% 60%;
  --color-secondary-800: 240 5% 40%;
  --color-secondary-900: 240 5% 25%;
  --color-secondary-contrast: 0 0% 0%;

  --bg-body: 280 100% 11%;
  --bg-body-level-1: 280 100% 14%;
  --bg-body-level-3: 280 100% 30%;

  --bg-body-dark: 0 0% 100%;
  --bg-body-light: 0 0% 0%;

  --input-bg: 0 0% 100% / 20%; 
  --input-bg-hover: 0 0% 100% / 50%; 
  --input-border-focus: 0 0% 100% / 70%;
}
`;
