import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ImageWithFallback, Input, Menu, Modal, Popover, Typography } from '@wbk/ui';
import { useWindowResize } from '@wbk/hooks';
import { twMerge } from 'tailwind-merge';
import { DecoratedSection } from '@wbk/contentful';
import { useAnalytics } from '@wbk/analytics';
import { type WebSection, useGetCountries } from '@wbk/contentful/api';
import { ChevronIcon } from '@wbk/svg';
import { createCookie } from '@wbk/utils';
import MobileSearchContent from '@/components/search/MobileSearchContent';
import SearchContent from '@/components/search/SearchContent';
import useSearch from '@/context/search/useSearch';
import useLocalization from '@/context/localization/useLocalization';
import SearchLocationFilter from './Location';

type Props = {
  section: WebSection;
  as?: 'section' | 'div';
  className?: string;
};

const HomeSearch = ({ section, as, className }: Props) => {
  const { t } = useTranslation(['common', 'home']);
  const navigate = useNavigate();
  const { width } = useWindowResize();
  const { locale, countryCode } = useLocalization();
  const { trackEvent } = useAnalytics();
  const { searchState, country, city, onSearch, showPopover, togglePopover } = useSearch();
  const { data, isLoading } = useGetCountries({ lang: locale }, { staleTime: Infinity });

  return (
    <DecoratedSection
      section={section}
      as={as}
      data-testid='home_search_section'
      className={twMerge('container max-w-4xl space-y-4 md:space-y-2', className)}
    >
      <div className='space-y-4'>
        {!!section?.title && (
          <Typography as='h1' variant='heading-XL' className='text-center'>
            {section.title}
          </Typography>
        )}

        {!!section?.subtitle && (
          <Typography as='div' variant='heading-S' className='text-center'>
            <span> {section.subtitle}</span>
            {isLoading ? (
              <div className='bg-body-level-1 rounded-S mx-1 -mb-1.5 inline-block h-6 w-32 animate-pulse self-center' />
            ) : data?.length ? (
              <Menu
                className='inline'
                render={({ open, selected }) => (
                  <Button theme='action' shape='text' className='p-1' onClick={open}>
                    <Typography variant='heading-S' className='underline'>
                      <span>{selected?.label}</span>
                      <ChevronIcon className='mx-1 inline h-3 w-3 rotate-90' />
                    </Typography>
                  </Button>
                )}
                value={countryCode || ''}
                options={(data || [])?.map((country) => ({
                  label: country.title,
                  text: (
                    <div className='flex items-center gap-2'>
                      <ImageWithFallback
                        src={`https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${country.countryCode.toLowerCase()}.png`}
                        width={20}
                        height={14}
                        alt={country.title}
                      />
                      <span>{country.title}</span>
                    </div>
                  ),
                  value: country.countryCode,
                }))}
                onChange={(selected) => {
                  createCookie({ name: 'location', value: selected.value, expiration: 1 });
                  window.location.reload();
                }}
              />
            ) : null}
          </Typography>
        )}
      </div>

      {width < 768 ? (
        <Modal
          fullscreen
          ignoreHistory
          unstyled
          show={showPopover}
          onClose={() => togglePopover(false)}
          className='data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top-[45%] data-[state=open]:slide-in-from-top-[45%] fixed top-0 z-50 duration-1000'
          render={() => {
            return (
              <div className='relative mx-auto'>
                <Button
                  shape='text'
                  theme='white'
                  className='border-input-border m-px w-full justify-start border px-3 py-3.5'
                  onClick={() => togglePopover(true)}
                  data-testid='mobile-search'
                  data-location='header'
                  data-title='search'
                >
                  <img
                    className='opacity-50'
                    src='/icons/common/search.svg'
                    width={18}
                    height={18}
                    alt=''
                  />
                  <p className='text-text-secondary shrink-0 text-sm'>
                    {t('common:search_placeholder')}
                  </p>
                </Button>

                <div className='absolute top-1/2 -translate-y-1/2 ltr:right-2 rtl:left-2'>
                  <SearchLocationFilter
                    render={({ label }) => (
                      <Button
                        as='div'
                        theme='action'
                        shape='text'
                        className='p-1'
                        role='button'
                        onClick={() => togglePopover(true)}
                      >
                        <Typography variant='body-S'>
                          <span>{label}</span>
                          <ChevronIcon className='mx-1 inline h-3 w-3 rotate-90' />
                        </Typography>
                      </Button>
                    )}
                  />

                  <div className='bg-primary hidden h-9 w-9 items-start justify-center rounded-md md:flex'>
                    <img
                      className='h-full'
                      src='/icons/common/search.svg'
                      width={18}
                      height={18}
                      alt=''
                    />
                  </div>
                </div>
              </div>
            );
          }}
          body={() => {
            return <MobileSearchContent />;
          }}
        />
      ) : (
        <Popover
          content={<SearchContent />}
          onOpenAutoFocus={(e) => e.preventDefault()}
          className='[&>div]:space-y-4'
        >
          <form
            className='relative z-20 mx-auto hidden w-full max-w-xl overflow-hidden py-1 md:block'
            onSubmit={(event) => {
              event.preventDefault();
              if (!searchState?.trim()) {
                return;
              }

              const params = new URLSearchParams();
              params.set('q', searchState || '');
              if (city) {
                params.set('city', city);
              }
              if (country) {
                params.set('country', country);
              }
              if (searchState?.trim()) {
                navigate(`/${locale}/search?${params.toString()}`);
              }
            }}
          >
            <Input
              type='search'
              inputMode='search'
              className='h-14 w-full truncate text-sm ltr:pr-48'
              placeholder={t('home:search_placeholder')}
              value={searchState || ''}
              data-testid='home_search_input'
              onChange={(value) => {
                if (!showPopover) {
                  togglePopover(true);
                }

                onSearch(value);
              }}
              onFocus={() => {
                togglePopover(true);
                trackEvent({
                  event: 'clicked_on_search',
                });
              }}
              onBlur={() => {
                togglePopover(false);
              }}
              startIcon={
                <img
                  src='/icons/common/search.svg'
                  className='absolute top-1/2 z-10 mx-2 -translate-y-1/2 transform opacity-60'
                  width={20}
                  height={20}
                  alt=''
                />
              }
            />

            <div className='absolute top-1/2 flex -translate-y-1/2 items-center gap-2 ltr:right-2 rtl:left-2'>
              <span className='bg-body-level-1 h-10 w-px' />
              <SearchLocationFilter
                render={({ label, open }) => (
                  <Button onClick={open} theme='action' shape='text' className='px-1'>
                    <Typography variant='body-S'>
                      <span> {label}</span>
                      <ChevronIcon className='mx-1 inline h-3 w-3 rotate-90' />
                    </Typography>
                  </Button>
                )}
              />
              <Button
                type='submit'
                aria-label='Search'
                data-testid='home_search_submit_button'
                className='shrink-0 p-2'
              >
                <Typography variant='body-S' strong>
                  {t('common:search')}
                </Typography>
              </Button>
            </div>
          </form>
        </Popover>
      )}
    </DecoratedSection>
  );
};

export default HomeSearch;
