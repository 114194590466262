export const VULNERABILITY_TYPES = [
  'remote_code_execution',
  'sql_injection',
  'xxe',
  'xss',
  'server_side_request_forgery',
  'directory_traversal_local_file_inclusion',
  'authentication_authorization_bypass',
  'privilege_escalation',
  'insecure_direct_object_reference',
  'misconfiguration',
  'web_cache_deception',
  'cors_misconfiguration',
  'crlf_injection',
  'cross_site_request_forgery',
  'open_redirect',
  'information_disclosure',
  'request_smuggling',
  'mixed_content',
];
