import { CurrencyPriceLabel } from '@wbk/currency';
import { useGetWalletHistory } from '@wbk/api/hooks';
import { Typography } from '@wbk/ui';
import useLocalization from '@/context/localization/useLocalization';

const WalletBalance = () => {
  const { locale } = useLocalization();
  const { data, isLoading } = useGetWalletHistory({ lang: locale });
  const balance = data?.total_balance || 0;
  const currency = data?.currency;

  if (isLoading) {
    return <div className='rounded-XS bg-body-level-1 h-6 w-14 animate-pulse' />;
  }

  return (
    <Typography variant='link-M' as='div'>
      <CurrencyPriceLabel
        className='shrink-0 leading-none'
        originalPrice={balance}
        currency={currency}
      />
    </Typography>
  );
};

export default WalletBalance;
