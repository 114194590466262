import { ContentfulMedia } from '@wbk/contentful';
import type { ContentfulPage } from '@wbk/contentful/api';

interface Props {
  data: ContentfulPage;
}

const Hero = ({ data }: Props) => {
  return (
    <section className='relative py-10 md:aspect-[3/1] lg:aspect-[4/1] 2xl:max-h-[420px] 2xl:w-full'>
      {data.background?.url && (
        <ContentfulMedia
          className='absolute inset-0 h-full w-full object-cover'
          media={data.background}
        />
      )}

      <span className='absolute inset-0 from-black/70 to-black/5 to-70% ltr:bg-gradient-to-r rtl:bg-gradient-to-l' />

      <div className='container relative h-full'>
        <div className='flex h-full w-full max-w-xl flex-col justify-center gap-5'>
          {data.logo && (
            <ContentfulMedia
              media={data.logo}
              className='h-16 w-16 object-contain md:h-20 md:w-20'
            />
          )}
          <h1 className='text-3xl md:text-5xl'>{data.title}</h1>
          {data.subtitle && <p className='text-text text-sm md:text-base'>{data.subtitle}</p>}
        </div>
      </div>
    </section>
  );
};

export default Hero;
