import { type RouteObject, redirect } from 'react-router-dom';
import { setItem } from '@wbk/utils';

export const referralRoutes: RouteObject[] = [
  {
    path: 'u/:referral_key',
    loader: ({ params }) => {
      const lang = params?.lang || 'en';
      const referral_key = params?.referral_key || '';
      setItem('referral_key', referral_key);

      return redirect(`/${lang}/signup`);
    },
  },
];
