import { Button, Modal } from '@wbk/ui';
import { SearchIcon } from '@wbk/svg';
import MobileSearchContent from '@/components/search/MobileSearchContent';
import useSearch from '@/context/search/useSearch';

const MobileSearch = () => {
  const { showPopover, togglePopover } = useSearch();

  return (
    <Modal
      fullscreen
      ignoreHistory
      unstyled
      show={showPopover}
      onClose={() => togglePopover(false)}
      className='data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top-[45%] data-[state=open]:slide-in-from-top-[45%] fixed top-0 z-50 duration-1000'
      render={() => (
        <Button
          shape='text'
          theme='action'
          className='flex max-w-xs items-center p-0'
          onClick={() => togglePopover(true)}
          data-testid='header_mobile_search_button'
          data-location='header'
          data-title='search'
        >
          <SearchIcon className='h-6 w-6 shrink-0' />
        </Button>
      )}
      body={() => <MobileSearchContent />}
    />
  );
};

export default MobileSearch;
