import { motion } from 'motion/react';
import AnimatedGrayLogo from '../ui/spinners/AnimatedGrayLogo';

type Props = {
  children: React.ReactNode;
  hasNextPage: boolean;
  fetchNextPage: () => void;
};

const PaginatedPageWrapper = ({ children, hasNextPage, fetchNextPage }: Props) => {
  return (
    <>
      {children}
      {hasNextPage && (
        <motion.div className='py-12' onViewportEnter={fetchNextPage}>
          <AnimatedGrayLogo className='mx-auto' />
        </motion.div>
      )}
    </>
  );
};

export default PaginatedPageWrapper;
