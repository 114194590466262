import React from 'react';
import type { RouteObject } from 'react-router-dom';

const DevicesPage = React.lazy(() => import('@/pages/devices'));

export const devicesRoutes: RouteObject[] = [
  {
    path: 'devices',
    element: <DevicesPage />,
  },
];
