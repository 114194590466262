import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useCookieConsent } from '@wbk/analytics';
import { Button, Toast, Typography } from '@wbk/ui';
import { readCookie } from '@wbk/utils';
import { AnimatePresence, motion } from 'motion/react';
import CookieSettings from './Settings';

const CookieConsentBar = () => {
  const { lang } = useParams<{ lang: string }>();
  const { t } = useTranslation('common');
  const { showConsent, acceptConsent, rejectConsent } = useCookieConsent();
  const toast = useRef<React.ComponentRef<typeof Toast>>(undefined);
  const isTwk = readCookie('twk') === 'true';

  return (
    <AnimatePresence initial={false}>
      {showConsent && (
        <motion.div
          key='cookie_consent_overlay'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className='bg-body-dark/20 fixed left-0 top-0 z-50 h-full w-full'
        />
      )}
      {showConsent && (
        <motion.div
          key='cookie_consent_bar'
          className='fixed bottom-0 z-[100] w-full'
          initial={{ y: '100%' }}
          animate={{ y: '0%' }}
          exit={{ y: '100%' }}
          transition={{ type: 'tween' }}
        >
          <div
            className='bg-body relative z-10 flex flex-col items-center gap-4 p-4 data-[twk=true]:pb-10 lg:flex-row lg:p-6'
            id='cookie_consent'
            data-twk={isTwk}
          >
            <Typography variant='body-S'>
              <Trans i18nKey='cookie.description' ns='common'>
                <Typography
                  variant='link-S'
                  color='primary'
                  as={Link}
                  to={`/${lang}/privacy`}
                ></Typography>
              </Trans>
            </Typography>

            <div className='flex shrink-0 flex-col gap-4 sm:flex-row sm:gap-2'>
              <CookieSettings />
              <div className='flex gap-2'>
                <Button onClick={() => acceptConsent()}>
                  <Typography>{t('common:cookie.accept_all')}</Typography>
                </Button>
                <Button shape='outlined' onClick={() => rejectConsent()}>
                  <Typography>{t('common:cookie.decline_all')}</Typography>
                </Button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return <Toast ref={toast} swipeDirection='down' className={`p-0 ${isTwk ? 'mb-10' : ''}`} />;
};

export default CookieConsentBar;
