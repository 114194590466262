import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'motion/react';
import { Button, DayPicker } from '@wbk/ui';
import { formatDate, parseDate, splitDateTime } from '@wbk/utils';
import { useDeviceDetect } from '@wbk/hooks';
import { CloseIcon } from '@wbk/svg';
import { useAnalytics } from '@wbk/analytics';
import useFilters from '@/context/filters/useSearch';
import useLocalization from '@/context/localization/useLocalization';

type DateRange = {
  from: Date | undefined;
  to?: Date | undefined;
};

const ExploreDateFilters = () => {
  const { t } = useTranslation('common');
  const { locale } = useLocalization();
  const { isMobile } = useDeviceDetect();
  const { date, selectFilter } = useFilters();
  const { trackEvent } = useAnalytics();
  const initialFrom = date?.split('_')?.[0];
  const initialTo = date?.split('_')?.[1];
  const safeFrom = initialFrom ? parseDate(initialFrom) : undefined;
  const safeTo = initialTo ? parseDate(initialTo) : undefined;
  const [showPicker, setShowPicker] = useState(!!safeFrom && !!safeTo);
  const [range, setRange] = useState<DateRange | undefined>({
    from: initialTo ? safeFrom : undefined,
    to: safeTo,
  });

  const handleRangeSelect = (range: DateRange | undefined) => {
    if (!range) return;

    setRange(range);

    if (range?.from && range.to) {
      const fromSplitted = splitDateTime(range.from, 'en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const toSplitted = splitDateTime(range.to, 'en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const from = `${fromSplitted.year}-${fromSplitted.month}-${fromSplitted.day}`;
      const to = `${toSplitted.year}-${toSplitted.month}-${toSplitted.day}`;
      selectFilter({ key: 'date', value: `${from}_${to}`, apply: !isMobile });
      trackEvent({
        event: 'clicked_on_select_dates',
        data: { type: 'custom' },
      });
    }
  };

  const clearRange = () => {
    setShowPicker(false);
    setRange(undefined);
  };

  return (
    <>
      <hr className='border-paper my-4 hidden lg:block' />
      <div className='space-y-4'>
        <h2 className='text-2xl font-medium'>{t('common:select_date')}</h2>
        <div className='flex flex-wrap gap-2'>
          {['today', 'tomorrow', 'thisweek'].map((d) => {
            const isActive = !showPicker && date === d;
            return (
              <Button
                key={d}
                role='radio'
                aria-checked={isActive}
                theme={isActive ? 'primary' : 'white'}
                shape={isActive ? 'solid' : 'outlined'}
                className='aria-[checked=false]:border-paper aria-[checked=false]:text-text-secondary gap-2 py-1.5 text-sm'
                onClick={() => {
                  selectFilter({ key: 'date', value: d, apply: !isMobile });
                  trackEvent({
                    event: 'clicked_on_select_dates',
                    data: { type: d },
                  });
                  if (showPicker) {
                    clearRange();
                  }
                }}
                phill
              >
                <span>{t(`common:${d}`)}</span>
                {isActive && <CloseIcon className='h-3 w-3 shrink-0' />}
              </Button>
            );
          })}

          <Button
            role='radio'
            aria-checked={showPicker}
            theme={showPicker ? 'primary' : 'white'}
            shape={showPicker ? 'solid' : 'outlined'}
            className='aria-[checked=false]:border-paper aria-[checked=false]:text-text-secondary gap-2 py-1.5 text-sm'
            onClick={() => setShowPicker((prev) => !prev)}
            phill
          >
            <span>{t('common:custom_dates')}</span>
            {showPicker && <CloseIcon className='h-3 w-3 shrink-0' />}
          </Button>

          <motion.div
            initial={false}
            className='overflow-hidden'
            animate={{ height: showPicker ? 'auto' : 0 }}
          >
            <DayPicker
              className='bg-body-level-1 w-full rounded-lg py-2'
              lang={locale}
              mode='range'
              selected={range}
              onSelect={handleRangeSelect}
              weekStartsOn={0}
              fromDate={new Date()}
              components={{
                CaptionLabel: ({ displayMonth }) => {
                  return (
                    <p className='px-2'>
                      {formatDate(displayMonth, locale, { month: 'long', year: 'numeric' })}
                    </p>
                  );
                },
              }}
            />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default ExploreDateFilters;
