import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { COUNTRIES } from '@wbk/utils';
import { useAuth } from '@wbk/auth';
import { OutlinedCheckIcon } from '@wbk/svg';
import { Divider, ImageWithPlaceholder, Typography } from '@wbk/ui';
import Seo from '@/components/headers/seo';
import { BusinessFormValues, PARTNERS_LIST } from '@/components/business/types';
import BusinessForm from '@/components/business/Form';
import Success from '@/components/business/Success';

const BusinessSupportPage = () => {
  const { t } = useTranslation(['business', 'common']);
  const { user } = useAuth();
  const params = useParams<{ booking_type: string }>();
  const userCountry = COUNTRIES.find((c) => c.code === user?.cell_country_code);

  const methods = useForm<BusinessFormValues>({
    defaultValues: {
      business_type: params.booking_type,
      step: 'contact_info',
      phone: {
        country: userCountry || COUNTRIES[0],
        mobile: user?.cell_number?.replace(`+${userCountry?.dial_code}`, '') || '',
      },
    },
  });

  const step = methods.watch('step');

  return (
    <>
      <Seo title={t('business:title')} />

      <FormProvider {...methods}>
        {step === 'success' ? (
          <section className='container !max-w-3xl py-12'>
            <Success />
          </section>
        ) : (
          <section className='container flex'>
            <div className='w-full shrink-0 grow lg:w-1/2'>
              <div className='max-w-xl space-y-6 py-12'>
                <FormProvider {...methods}>
                  <BusinessForm />
                </FormProvider>
              </div>
            </div>

            <div className='border-body-dark/20 hidden w-1/2 grow lg:block ltr:border-l rtl:border-r'>
              <ImageWithPlaceholder
                src='/images/business/form-bg.png'
                alt='Business'
                className='w-full'
                width={687}
                height={456}
              />

              <div className='space-y-8 p-12'>
                <Typography as='h3' variant='heading-L'>
                  {t('business:form_benefits.title')}
                </Typography>

                <ul className='space-y-2'>
                  {[...Array(3)].map((_, index) => (
                    <li key={index} className='flex items-center gap-2.5'>
                      <OutlinedCheckIcon className='text-success h-6 w-6 shrink-0' />
                      <Typography variant='label-M'>
                        {t(`business:form_benefits.desc_${index + 1}`)}
                      </Typography>
                    </li>
                  ))}
                </ul>

                <Divider />

                <Typography variant='label-M'>{t('business:form_benefits.trusted_by')}</Typography>

                <div className='flex flex-wrap gap-6'>
                  {PARTNERS_LIST.map((p) => {
                    return <img src={p.href} alt={p.alt} key={p.href} />;
                  })}
                </div>
              </div>
            </div>
          </section>
        )}
      </FormProvider>
    </>
  );
};

export default BusinessSupportPage;
