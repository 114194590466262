import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDeviceDetect } from '@wbk/hooks';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import useLocalization from '@/context/localization/useLocalization';

type Props = {
  /**
   * The intended deeplink to open the mobile app.
   */
  goTo?: string;
  /**
   * The fallback page to redirect to if the user is not on a mobile device.
   * @default 'profile/bookings'
   */
  fallback?: string;
};
/**
 * AppCheckRedirect Component
 *
 * This component checks the user's device type. If the user is on a mobile device,
 * it attempts to open the mobile app or store. If the user is not on a mobile device,
 * they are redirected to a specified fallback page. By default, the fallback is the
 * "profile/bookings" (order history) page.
 *
 * @param {Props}
 *
 * @returns {JSX.Element} FullpageAnimatedLogo - A full-page animated logo displayed
 * while the redirect or app-opening process is in progress.
 */

const AppCheckRedirect = ({ goTo = 'webook://tickets', fallback = 'profile/bookings' }: Props) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();
  const { locale } = useLocalization();
  const [search] = useSearchParams();
  const redirect = search.get('redirect');
  const webFallback = search.get('fallback') || fallback;

  // Temp code
  const isActivateCode = redirect?.includes('activateCode');

  useEffect(() => {
    if (!isMobile) {
      navigate(`/${locale}/${webFallback}`);
    }

    const timer = setTimeout(() => {
      navigate(`/${locale}/${webFallback}`);
    }, 5000);

    if (!isActivateCode) {
      // Attempt to open the mobile app
      window.location.href = redirect || goTo;
    }

    return () => clearTimeout(timer);
  }, [navigate, isMobile, locale, webFallback, goTo, redirect, isActivateCode]);

  return <FullpageAnimatedLogo />;
};

export default AppCheckRedirect;
