import { Fragment } from 'react';
import { useDeviceDetect } from '@wbk/hooks';
import { AdSlotLB, AdSlotMPU, AdSlotVideo, useAds } from '@smc/ads';
import { BusinessSection, EventItem, EventListItem } from '@wbk/contentful';
import type { ContentfulEvent, Zone } from '@wbk/contentful/api';

type Props = {
  events: (ContentfulEvent | Zone)[];
};

const ExploreGrid = ({ events }: Props) => {
  const { renderMpuPosition } = useAds();
  const { isMobile } = useDeviceDetect();
  const keyvalue = window.location.pathname + window.location.search;

  return events.map((event, i) => {
    const mpuPosition = renderMpuPosition(events.length, i);

    return (
      <Fragment key={event.id + i}>
        <div className='mx-auto w-full'>
          {isMobile ? <EventListItem event={event} /> : <EventItem event={event} />}
        </div>

        {i === 3 && <BusinessSection mini />}

        {/* Ads */}
        {i === 2 && <AdSlotLB adUnit='explore/lb' className='col-span-full mx-auto' />}

        {/* mobile ads */}
        {isMobile && (
          <>
            {i === 2 && <AdSlotVideo />}
            {i === 6 && (
              <AdSlotMPU
                slotId={`${event.id}-${i}`}
                adUnit='explore/mpu'
                targeting={{ Categories: keyvalue }}
                className='mx-auto overflow-hidden'
              />
            )}
          </>
        )}

        {/* desktop */}
        {mpuPosition > 0 && !isMobile && (
          <AdSlotMPU
            slotId={`${event.id}-${i}`}
            adUnit={`explore/mpu${mpuPosition}`}
            targeting={{ Categories: keyvalue }}
            className='mx-auto flex grow flex-col overflow-hidden rounded-md'
          />
        )}
      </Fragment>
    );
  });
};

export default ExploreGrid;
