import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { isArabic } from '@wbk/utils';
import { AdSlotVideo } from '@smc/ads';
import { useDeviceDetect } from '@wbk/hooks';
import { Button, Typography } from '@wbk/ui';
import { ChevronIcon } from '@wbk/svg';
import Seo from '@/components/headers/seo';
import FiltersProvider from '@/context/filters/provider';
import ExploreLocationFilter from '@/components/explore/filters/Location';
import ExploreFilters from '@/components/explore/filters';
import ExploreSelectedFilters from '@/components/explore/filters/Selected';
import ExploreListing from '@/components/explore/ExploreListing';
import AppSmartBanner from '@/components/app/SmartBanner';

const SearchPage = () => {
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const { isDesktop } = useDeviceDetect();
  const search = searchParams.get('q');
  const isArabicSearch = isArabic(search);
  const lang = isArabicSearch ? 'ar-SA' : 'en-US';

  return (
    <>
      <AppSmartBanner argument='search' />

      <FiltersProvider>
        <Seo title={t('common:search_result_of', { search })} />

        {!!search && (
          <section className='container pb-4 pt-12'>
            <div className='[&>div]:inline-flex'>
              <Typography as='h1' variant='heading-XL' className='inline'>
                {t('common:search_result_of', { search })}
              </Typography>
              <ExploreLocationFilter
                className='hidden border-none bg-transparent p-0 lg:block'
                render={({ label, open }) => (
                  <Button theme='action' shape='text' className='w-auto p-1' onClick={open}>
                    <Typography variant='heading-XL'>
                      <span> {label}</span>
                      <ChevronIcon className='mx-1 inline h-3 w-3 rotate-90' />
                    </Typography>
                  </Button>
                )}
              />
            </div>
          </section>
        )}

        <div className='container grid grid-cols-10 gap-4 py-6 xl:grid-cols-4'>
          <aside className='col-span-full hidden h-full w-full lg:col-span-3 lg:block xl:col-span-1'>
            {isDesktop && <AdSlotVideo className='sticky top-20 z-20' />}
            <ExploreFilters className='[&>hr:first-child]:hidden' />
          </aside>
          <section className='col-span-full w-full lg:col-span-7 xl:col-span-3'>
            <ExploreFilters mobile />
            <ExploreSelectedFilters />
            <ExploreListing predefined={{ lang }} />
          </section>
        </div>
      </FiltersProvider>
    </>
  );
};

export default SearchPage;
