export const ART_SLUG = 'art-week-riyadh';
export const ORGANIZATION_ART_SLUG = 'art-week';

export const ART_CSS = `
#main, .prose {
    --color-primary-100: 57 70% 89%;
    --color-primary-200: 57 70% 83%;
    --color-primary-300: 57 70% 77%;
    --color-primary-400: 57 70% 71%;
    --color-primary-500: 57 70% 79%;
    --color-primary-600: 57 70% 55%;
    --color-primary-700: 57 70% 40%;
    --color-primary-800: 57 70% 27%;
    --color-primary-900: 57 70% 19%;
    --color-primary-contrast: 0 0% 0%;

    --color-secondary-100: 10 75% 91%;
    --color-secondary-200: 10 75% 81%;
    --color-secondary-300: 10 75% 71%;
    --color-secondary-400: 10 75% 61%;
    --color-secondary-500: 10 75% 50%;
    --color-secondary-600: 10 75% 40%;
    --color-secondary-700: 10 75% 30%;
    --color-secondary-800: 10 75% 21%;
    --color-secondary-900: 10 75% 15%;
    --color-secondary-contrast: 0 0% 100%;

    --color-tertiary-100: 103 22% 91%;
    --color-tertiary-200: 103 22% 81%;
    --color-tertiary-300: 103 22% 71%;
    --color-tertiary-400: 103 22% 61%;
    --color-tertiary-500: 103 22% 27%;
    --color-tertiary-600: 103 22% 40%;
    --color-tertiary-700: 103 22% 30%;
    --color-tertiary-800: 103 22% 21%;
    --color-tertiary-900: 103 22% 15%;
    --color-tertiary-contrast: 0 0% 100%;
  }
`;
