import { Link } from 'react-router-dom';
import { WebsiteConfig } from '@wbk/contentful/api';
import { ImageWithFallback } from '@wbk/ui';
import LanguageCurrencySwitch from '@/components/common/LanguageCurrencySwitch';
import UserProfileButton from '@/components/layout/header/UserProfileButton';
import DesktopSearch from '@/components/search/DesktopSearch';
import useLocalization from '@/context/localization/useLocalization';
import NavLink from './NavLink';

type Props = {
  type?: LayoutType;
  config: WebsiteConfig;
};

const DesktopHeader = ({ type, config }: Props) => {
  const { locale } = useLocalization();
  const LogoComponent = type === 'booking' ? 'div' : Link;

  return (
    <nav
      data-type={type}
      className='container flex items-center justify-between py-3 data-[type=booking]:py-1'
    >
      <div className='flex min-h-10 grow items-center gap-1 md:gap-4'>
        <LogoComponent
          data-testid='header_desktop_logo'
          to={`/${locale}`}
          className='logo-image relative z-20 block shrink-0 hover:opacity-90'
          data-location='header'
          aria-label='webook.com Logo'
        >
          <ImageWithFallback
            className='max-h-10 max-w-16 data-[small=true]:max-h-8 data-[small=true]:max-w-14'
            src={config.logo.url}
            data-small={type === 'booking'}
            width={64}
            height={40}
            alt=''
          />
        </LogoComponent>
        {type === 'main' && (
          <ul className='hidden shrink-0 items-center text-sm md:flex' role='menu'>
            {config.header.topNavigationCollection.items.map((link) => {
              if (!link) return null;
              return (
                <li key={link.id} role='presentation'>
                  <NavLink
                    data-testid={`header_nav_link_${link.id}`}
                    link={link}
                    className='text-text-secondary text-body-S'
                    data-category={link.label}
                    aria-label={link.label}
                  />
                </li>
              );
            })}
          </ul>
        )}

        {type === 'main' && <DesktopSearch />}
      </div>
      <ul className='flex items-center justify-end gap-2 text-sm xl:gap-2 xl:text-base' role='menu'>
        {type === 'main' &&
          config.header.topSubNavigationCollection.items.map((link) => {
            if (!link) return null;
            return (
              <li
                data-testid={`header_nav_link_${link.id}`}
                key={link.id}
                role='presentation'
                className='hidden lg:flex'
              >
                <NavLink
                  link={link}
                  data-category={link.label}
                  aria-label={link.label}
                  className='text-body-S'
                />
              </li>
            );
          })}

        <li
          className='z-40 shrink-0 [&>button]:px-1 [&>button]:text-sm md:[&>button]:px-2'
          role='presentation'
        >
          <LanguageCurrencySwitch />
        </li>

        {type === 'main' && (
          <li className='relative shrink-0 text-sm' role='presentation'>
            <UserProfileButton />
          </li>
        )}
      </ul>
    </nav>
  );
};

export default DesktopHeader;
