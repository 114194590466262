import { CircleCloseIcon, HollowShieldIcon, OutlinedCheckIcon } from '@wbk/svg';
import { Button, Divider, ResponsiveModal, Typography } from '@wbk/ui';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRouter } from '@wbk/config';
import { FormProvider, useForm } from 'react-hook-form';
import { COUNTRIES } from '@wbk/utils';
import SecurityForm from '@/components/security/SecurityForm';
import { VULNERABILITY_TYPES } from '@/constants/security';
interface FormProps {
  full_name: string;
  email: string;
  phoneCountry: (typeof COUNTRIES)[number];
  phone: string;
  vulnerability_type: string[];
  vulnerability_description: string;
  vulnerability_replicate: string;
  vulnerability_potential_risk: string;
  vulnerability_severity: string;
  images: File[];
  what_happened: string;
  what_should_happened: string;
  agreement: boolean;
  loading?: boolean;
  submitted?: boolean;
}

const SecurityPage = () => {
  const { t } = useTranslation(['security']);
  const { locale } = useRouter();

  const guidelineEntries = [
    {
      title: t('security:guidelines.guideline_1_title'),
      description: t('security:guidelines.guideline_1_description'),
    },
    {
      title: t('security:guidelines.guideline_2_title'),
      description: t('security:guidelines.guideline_2_description'),
    },
    {
      title: t('security:guidelines.guideline_3_title'),
      description: t('security:guidelines.guideline_3_description'),
    },
    {
      title: t('security:guidelines.guideline_4_title'),
      description: t('security:guidelines.guideline_4_description'),
    },
    {
      title: t('security:guidelines.guideline_5_title'),
      description: t('security:guidelines.guideline_5_description'),
    },
    {
      title: t('security:guidelines.guideline_6_title'),
      description: t('security:guidelines.guideline_6_description'),
    },
    {
      title: t('security:guidelines.guideline_7_title'),
      description: t('security:guidelines.guideline_7_description'),
    },
    {
      title: t('security:guidelines.guideline_8_title'),
      description: t('security:guidelines.guideline_8_description'),
    },
    {
      title: t('security:guidelines.guideline_9_title'),
      description: t('security:guidelines.guideline_9_description'),
    },
    {
      title: t('security:guidelines.guideline_10_title'),
      description: t('security:guidelines.guideline_10_description'),
    },
  ];

  const notEligibleEntries = [
    { title: t('security:not_eligible.rule_1') },
    { title: t('security:not_eligible.rule_2') },
    { title: t('security:not_eligible.rule_3') },
    { title: t('security:not_eligible.rule_4') },
    { title: t('security:not_eligible.rule_5') },
    { title: t('security:not_eligible.rule_6') },
    { title: t('security:not_eligible.rule_7') },
    { title: t('security:not_eligible.rule_8') },
    { title: t('security:not_eligible.rule_9') },
    { title: t('security:not_eligible.rule_10') },
    { title: t('security:not_eligible.rule_11') },
    { title: t('security:not_eligible.rule_12') },
    { title: t('security:not_eligible.rule_13') },
    { title: t('security:not_eligible.rule_14') },
    { title: t('security:not_eligible.rule_15') },
    { title: t('security:not_eligible.rule_16') },
    { title: t('security:not_eligible.rule_17') },
    { title: t('security:not_eligible.rule_18') },
    { title: t('security:not_eligible.rule_19') },
    { title: t('security:not_eligible.rule_20') },
    { title: t('security:not_eligible.rule_21') },
    { title: t('security:not_eligible.rule_22') },
    { title: t('security:not_eligible.rule_23') },
    { title: t('security:not_eligible.rule_24') },
    { title: t('security:not_eligible.rule_25') },
  ];

  const methods = useForm<FormProps>({
    defaultValues: {
      full_name: '',
      email: '',
      phoneCountry: COUNTRIES.find((c) => c.code === 'SA'),
      phone: '',
      vulnerability_type: [],
      vulnerability_description: '',
      vulnerability_replicate: '',
      vulnerability_potential_risk: '',
      vulnerability_severity: '',
      images: [],
      what_happened: '',
      what_should_happened: '',
      agreement: false,
      loading: false,
      submitted: false,
    },
  });

  const navigate = useNavigate();

  const submitted = methods.watch('submitted');
  const loading = methods.watch('loading');

  return (
    <FormProvider {...methods}>
      <section className='container'>
        <div className='flex flex-col-reverse justify-between gap-6 pt-6 lg:flex-row lg:py-14'>
          <div className='max-w-2xl grow space-y-6'>
            <Typography variant='display-S'>{t('security:title')}</Typography>
            <Typography variant='body-L'>{t('security:description')}</Typography>
            <Typography variant='body-L'>
              <Trans i18nKey='security:description_2'>
                <strong></strong>
              </Trans>
            </Typography>
            <div className='space-y-6 pb-6 lg:hidden'>
              <ResponsiveModal
                type='sheet'
                title={t('security:form.form_header')}
                cancel={{
                  key: submitted ? 'another-response' : 'submit-form',
                  type: submitted ? 'button' : 'submit',
                  theme: submitted ? 'white' : 'primary',
                  shape: submitted ? 'outlined' : 'solid',
                  className: 'w-full',
                  label: submitted
                    ? t('security:form.submit_another_report')
                    : t('security:form.submit'),
                  form: submitted ? undefined : 'security-form',
                  loading: loading,
                  onClick: () => {
                    if (submitted) {
                      methods.setValue('submitted', false);
                      methods.reset();
                    }
                  },
                }}
                action={
                  submitted
                    ? {
                        label: t('security:return_to_home'),
                        onClick: () => navigate(`/${locale}`),
                        theme: 'white',
                        shape: 'solid',
                      }
                    : false
                }
                render={({ open }) => (
                  <Button type='button' onClick={open}>
                    {t('security:form.submit_report')}
                  </Button>
                )}
                body={() => <SecurityForm />}
              />

              <Divider />
            </div>
          </div>
          <HollowShieldIcon />
        </div>

        <div className='flex justify-between gap-6 pb-6'>
          <div className='space-y-6'>
            <div className='space-y-4'>
              <div>
                <Typography variant='heading-M'>{t('security:guidelines.title')}</Typography>
                <Typography color='text-secondary'>{t('security:guidelines.subtitle')}</Typography>
              </div>
              <ul className='space-y-6'>
                {guidelineEntries.map((item, idx) => (
                  <li key={idx} className='flex items-center gap-3'>
                    <span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 rtl:pt-1.5'>
                      {idx + 1}
                    </span>
                    <div className='space-y-1'>
                      <Typography variant='label-M'>{item.title}</Typography>
                      <Typography variant='body-S' color='text-secondary'>
                        {item.description}
                      </Typography>
                    </div>
                  </li>
                ))}
              </ul>
              <Divider className='!my-10' />
              <div className='space-y-6'>
                <Typography variant='heading-L'>{t('security:eligible.title')}</Typography>
                <ul className='space-y-6'>
                  {VULNERABILITY_TYPES.slice(0, 6).map((item, idx) => (
                    <li key={idx} className='flex items-center gap-3'>
                      <OutlinedCheckIcon className='h-6 w-6 shrink-0' />
                      <Typography variant='label-M'>
                        {t(`security:form.vulnerability_type_${item}`)}
                      </Typography>
                    </li>
                  ))}
                </ul>
                <ResponsiveModal
                  type='modal'
                  action={false}
                  title={t('security:eligible.title')}
                  body={() => {
                    return (
                      <ul className='space-y-6'>
                        {VULNERABILITY_TYPES.map((item, idx) => (
                          <li key={idx} className='flex items-center gap-3'>
                            <OutlinedCheckIcon className='h-6 w-6 shrink-0' />
                            <Typography variant='label-M'>
                              {t(`security:form.vulnerability_type_${item}`)}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    );
                  }}
                  render={({ open }) => (
                    <Button
                      className='p-1 text-sm underline'
                      type='button'
                      theme='white'
                      shape='text'
                      onClick={open}
                    >
                      {t('security:read_more')}
                    </Button>
                  )}
                />
              </div>
              <Divider className='!my-10' />
              <div className='space-y-6 pb-10'>
                <Typography variant='heading-L'>{t('security:not_eligible.title')}</Typography>
                <ul className='space-y-6'>
                  {notEligibleEntries.splice(0, 6).map((item, idx) => (
                    <li key={idx} className='flex items-center gap-3'>
                      <CircleCloseIcon className='text-error h-6 w-6 shrink-0' />
                      <Typography variant='label-M'>{item.title}</Typography>
                    </li>
                  ))}
                </ul>
                <ResponsiveModal
                  type='modal'
                  action={false}
                  title={t('security:not_eligible.title')}
                  body={() => {
                    return (
                      <ul className='bg-paper space-y-6'>
                        {notEligibleEntries.map((item, idx) => (
                          <li key={idx} className='flex items-center gap-3'>
                            <CircleCloseIcon className='text-error h-6 w-6 shrink-0' />
                            <Typography variant='label-M'>{item.title}</Typography>
                          </li>
                        ))}
                      </ul>
                    );
                  }}
                  render={({ open }) => (
                    <Button
                      className='p-1 text-sm underline'
                      type='button'
                      theme='white'
                      shape='text'
                      onClick={open}
                    >
                      {t('security:read_more')}
                    </Button>
                  )}
                />
              </div>
              <ResponsiveModal
                type='sheet'
                title={t('security:form.form_header')}
                action={{
                  key: submitted ? 'another-response' : 'submit-form',
                  type: submitted ? 'button' : 'submit',
                  theme: submitted ? 'white' : 'primary',
                  shape: submitted ? 'outlined' : 'solid',
                  className: 'w-full',
                  label: submitted
                    ? t('security:form.submit_another_report')
                    : t('security:form.submit'),
                  form: submitted ? undefined : 'security-form',
                  loading: loading,
                  onClick: () => {
                    if (submitted) {
                      methods.setValue('submitted', false);
                      methods.reset();
                    }
                  },
                }}
                cancel={
                  submitted
                    ? {
                        label: t('security:return_to_home'),
                        onClick: () => navigate(`/${locale}`),
                        theme: 'white',
                        shape: 'solid',
                      }
                    : false
                }
                render={({ open }) => {
                  return (
                    <div className='flex w-full flex-col space-y-2 lg:hidden'>
                      <Button type='button' onClick={open} className='w-full py-3'>
                        {t('security:form.submit_report')}
                      </Button>
                      <Typography
                        variant='body-S'
                        className='!mt-2 max-w-80'
                        color='text-secondary'
                      >
                        <Trans i18nKey='security:form.terms_and_conditions'>
                          <Button
                            theme='transparent'
                            shape='text'
                            className='text-text-secondary p-0 underline'
                            as={Link}
                            to={`/${locale}/terms`}
                          ></Button>
                          <Button
                            theme='transparent'
                            shape='text'
                            className='text-text-secondary p-0 underline'
                            as={Link}
                            to={`/${locale}/privacy`}
                          ></Button>
                        </Trans>
                      </Typography>
                    </div>
                  );
                }}
                body={() => {
                  return <SecurityForm />;
                }}
              />
            </div>
          </div>
          <div className='hidden w-full max-w-lg lg:block'>
            <SecurityForm />
          </div>
        </div>
      </section>
    </FormProvider>
  );
};
export default SecurityPage;
