import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import {
  AmericanExpressIcon,
  ApplePayIcon,
  MadaIcon,
  MasterCardIcon,
  TabbyIcon,
  UnionPayIcon,
  VisaIcon,
} from '@wbk/svg';

type Props = {
  onlyLogos?: boolean;
  withTabby?: boolean;
  className?: string;
};

const PaymentMethods = ({ onlyLogos, withTabby, className = '' }: Props) => {
  const { t } = useTranslation('booking');

  const logos = useMemo(() => {
    const LOGOS = [
      MadaIcon,
      ApplePayIcon,
      VisaIcon,
      MasterCardIcon,
      AmericanExpressIcon,
      UnionPayIcon,
    ];

    if (withTabby) {
      LOGOS.push(TabbyIcon);
    }
    return LOGOS;
  }, [withTabby]);

  if (onlyLogos) {
    return (
      <div className={twMerge('flex items-center gap-4', className)}>
        {logos.map((Logo, i) => (
          <Logo key={i} className={className} />
        ))}
      </div>
    );
  }
  return (
    <div
      className={twMerge(
        'border-paper flex flex-wrap items-center justify-center rounded-lg border border-t-0',
        className
      )}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='border-paper mt-0 h-5 w-full border-t ltr:rounded-l-lg rtl:rounded-r-lg'></div>
        <p className='text-text-secondary -mt-4 shrink-0 grow px-2 text-center text-xs'>
          {t('booking:available_pay_methods')}
        </p>
        <div className='border-paper mt-0 h-5 w-full border-t ltr:rounded-r-lg rtl:rounded-l-lg'></div>
      </div>
      <div className='-mt-2 flex w-full flex-wrap items-center justify-center gap-4 px-1 pb-1'>
        {logos.map((Logo, i) => (
          <Logo key={i} />
        ))}
      </div>
    </div>
  );
};
export default PaymentMethods;
