import type { Country } from '@wbk/utils';

export const BUSINESS_TYPES = [
  'event',
  'experience',
  'restaurant',
  'conference',
  'facility',
] as const;

export const ADDITIONAL_SERVICES = [
  'manpower_support',
  'marketing_promotions',
  'live_streaming',
  'technical_integrations_with_third_party',
  'other',
] as const;

export const BOOKING_NUMBER_ATTENDESS = ['1_50', '50_100', '100_200', 'above_200'] as const;

export type BusinessFormValues = {
  step: 'contact_info' | 'business_info' | 'success';
  business_type: string;
  business_name: string;
  date: string;
  country: string;
  city: string;
  attendees_count: string;
  description: string;
  full_name: string;
  entity_name: string;
  job_title: string;
  email: string;
  branches_count: string;
  online_booking_system: string;
  phone: {
    country: Country;
    mobile: string;
  };
};

export const PARTNERS_LIST = [
  {
    href: '/images/business/partners/saudi-tourism.svg',
    alt: 'saudi-tourism',
  },
  {
    href: '/images/business/partners/formula-e.svg',
    alt: 'formula-e',
  },
  {
    href: '/images/business/partners/fia.svg',
    alt: 'fia',
  },
  {
    href: '/images/business/partners/cool-inc.svg',
    alt: 'cool-inc',
  },
  {
    href: '/images/business/partners/mfc.svg',
    alt: 'mfc',
  },
  {
    href: '/images/business/partners/hogho.svg',
    alt: 'hogho',
  },
  {
    href: '/images/business/partners/e-sport-cup.svg',
    alt: 'e-sport-cup',
  },
  {
    href: '/images/business/partners/jeddah-season.svg',
    alt: 'jeddah-season',
  },
  {
    href: '/images/business/partners/timar.svg',
    alt: 'timar',
  },
  {
    href: '/images/business/partners/misk.svg',
    alt: 'misk',
  },
  {
    href: '/images/business/partners/the-line.svg',
    alt: 'the-line',
  },
  {
    href: '/images/business/partners/sfa.svg',
    alt: 'sfa',
  },
  {
    href: '/images/business/partners/nofa.svg',
    alt: 'nofa',
  },
];
