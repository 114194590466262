import { useTranslation } from 'react-i18next';
import { Button, ImageWithFallback } from '@wbk/ui';
import { Product } from '@wbk/api';
import { useDeviceDetect } from '@wbk/hooks';
import { CurrencyPriceLabel } from '@wbk/currency';
import { CartIcon } from '@wbk/svg';

type Props = {
  product: Product;
  showBuyButton?: boolean;
};

const ShopProductItem = ({ product, showBuyButton = true }: Props) => {
  const { t } = useTranslation(['common']);
  const { isWebView } = useDeviceDetect();

  return (
    <a
      href={product.link}
      target={isWebView ? '_self' : '_blank'}
      className='flex h-full flex-col'
      rel='noreferrer noopener'
    >
      <ImageWithFallback
        src={product.featured_image_url}
        alt={product.title.rendered}
        width={300}
        height={300}
        className='aspect-[2/3] rounded-lg object-cover'
      />

      <div className='flex grow flex-col gap-1.5 py-3'>
        <div
          className='line-clamp-2 grow text-lg leading-normal'
          dangerouslySetInnerHTML={{ __html: product.title.rendered }}
        />
        <CurrencyPriceLabel
          originalPrice={parseFloat(product._regular_price) || parseFloat(product._price) || 0}
          discountPrice={parseFloat(product._sale_price)}
          currency='SAR'
        />
        {showBuyButton && (
          <Button
            className='!mt-auto inline-flex h-8 items-center self-start px-2 py-0 text-sm'
            tabIndex={-1}
            theme='white'
            shape='outlined'
          >
            <CartIcon className='h-4 w-4' />
            <span>{t('common:add')}</span>
          </Button>
        )}
      </div>
    </a>
  );
};

export default ShopProductItem;
