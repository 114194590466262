import { useGetCategories } from '@wbk/contentful/api';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from '@wbk/hooks';
import { useAnalytics } from '@wbk/analytics';
import useLocalization from '@/context/localization/useLocalization';
import useFilters from '@/context/filters/useSearch';
import FilterCheckboxList from './CheckboxList';

const ExploreCategoryFilters = () => {
  const { locale, detectLoading } = useLocalization();
  const { t } = useTranslation('common');
  const { category, country, selectFilter } = useFilters();
  const { isMobile } = useDeviceDetect();
  const { trackEvent } = useAnalytics();

  const { data, isFetching } = useGetCategories(
    {
      lang: locale,
      where: { country: country ? { countryCode: country } : {}, childrenCollection_exists: true },
    },
    { enabled: !detectLoading }
  );

  return (
    <FilterCheckboxList
      title={t('common:category')}
      list={data?.map((cat) => ({
        title: cat.title,
        value: cat.slug,
        // children: cat.childrenCollection?.items.map((child) => ({
        //   title: child.title,
        //   value: child.slug,
        // })),
      }))}
      value={category || []}
      onChange={(value) => {
        const newAdded = !category?.includes(value);
        if (newAdded) {
          trackEvent({
            event: 'clicked_on_category',
            data: { category_slug: value },
          });
        }
        selectFilter({ key: 'category', value: [value], apply: !isMobile });
      }}
      loading={isFetching}
    />
  );
};

export default ExploreCategoryFilters;
