import SurveyPage from '@/pages/survey/slug';
import LinkCheckPage from '@/pages/survey/link-check';
import type { RouteObject } from 'react-router-dom';

export const surveyRoutes: RouteObject[] = [
  {
    path: 'survey/:slug',
    element: <SurveyPage />,
  },
  {
    path: 'survey/link-check',
    element: <LinkCheckPage />,
  },
];
