import React from 'react';
import { type RouteObject, redirect } from 'react-router-dom';
const ExplorePage = React.lazy(() => import('@/pages/explore'));

export const exploreRoutes: RouteObject[] = [
  {
    path: 'explore',
    element: <ExplorePage />,
  },
  {
    path: 'events',
    loader: ({ params }) => redirect(`/${params.lang}/explore?type=events`),
  },
  {
    path: 'experiences',
    loader: ({ params }) => redirect(`/${params.lang}/explore?type=experiences`),
  },
  {
    path: 'restaurants',
    loader: ({ params }) => redirect(`/${params.lang}/explore?type=restaurants`),
  },
  {
    path: 'shows',
    loader: ({ params }) => redirect(`/${params.lang}/explore?type=shows`),
  },
  {
    path: 'packages',
    loader: ({ params }) => redirect(`/${params.lang}/explore?type=packages`),
  },
];
