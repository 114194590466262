import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, MobileInput, Select, Typography } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useBusiness } from '@wbk/api/hooks';
import { EMAIL_PATTERN } from '@wbk/utils';
import useLocalization from '@/context/localization/useLocalization';
import { BUSINESS_TYPES, BusinessFormValues } from '@/components/business/types';

const ContactInfoFormItems = () => {
  const { t } = useTranslation(['business', 'common']);
  const { control, watch, handleSubmit, setValue, getValues } =
    useFormContext<BusinessFormValues>();
  const navigate = useNavigate();
  const { locale } = useLocalization();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutateAsync } = useBusiness();

  const businessType = watch('business_type');
  const handleSelectChange = (selectedValue: string) => {
    navigate(`/${locale}/business/${selectedValue}`, { replace: true });
    setValue('business_type', selectedValue);
  };

  const onNextStep = async () => {
    try {
      setIsSubmitting(true);
      const formValues = getValues();

      await mutateAsync({
        ...formValues,
        phone: `${formValues.phone.country?.dial_code}${formValues.phone.mobile}`,
        step: 'contact_info',
      });
      setValue('step', 'business_info');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='space-y-4'>
      <Select
        value={businessType}
        onChange={(option) => handleSelectChange(option.value)}
        key={businessType}
        label={<Typography variant='label-M'>{t('business:form.business_type')}</Typography>}
        hideErrorSpace
        className='border-input-border rounded-S border'
        options={BUSINESS_TYPES.filter((type) => type !== 'experience').map((type) => ({
          id: type,
          text: t(`business:business_types.${type}_title`),
          icon: (
            <img
              src={`/images/business/options/${type}.png`}
              alt={type}
              className='h-4 w-4 rounded-sm'
            />
          ),
          value: type,
        }))}
      />
      <Controller
        control={control}
        name='phone'
        rules={{
          required: t('common:validation.required'),
          validate: (value) => {
            const hasValidation = value?.country?.mobile_starts_with?.length;
            if (hasValidation) {
              const validStart = value?.country?.mobile_starts_with?.some((start) =>
                value?.mobile?.startsWith(start)
              );
              const validLength =
                value?.mobile.length >= Number(value?.country.phone_number_lengths?.[0]);
              return (validStart && validLength) || t('common:validation.invalid_mobile');
            }

            return true;
          },
        }}
        render={({ field: { value, onChange, ...props }, fieldState: { error } }) => {
          return (
            <MobileInput
              {...props}
              country={value?.country}
              mobile={value?.mobile}
              onChange={(name, v) => {
                onChange({ ...value, [name as keyof typeof value]: v });
              }}
              label={<Typography variant='label-M'>{t('business:form.phone_number')}</Typography>}
              placeholder='+966 XXX XX'
              error={error?.message}
            />
          );
        }}
      />

      <Controller
        control={control}
        name='email'
        defaultValue=''
        rules={{
          required: t('common:validation.required'),
          pattern: {
            value: EMAIL_PATTERN,
            message: t('auth_common:validation.invalid_email'),
          },
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Input
              {...field}
              type='email'
              label={<Typography variant='label-M'>{t('business:form.email')}</Typography>}
              placeholder='eg george@webook.com'
              error={error?.message}
            />
          );
        }}
      />
      <Controller
        control={control}
        name='full_name'
        defaultValue=''
        rules={{ required: t('common:validation.required') }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Input
              {...field}
              label={<Typography variant='label-M'>{t('business:form.full_name')}</Typography>}
              placeholder='eg John Doe '
              error={error?.message}
            />
          );
        }}
      />
      <Button
        theme='action'
        className='w-full sm:w-auto'
        onClick={handleSubmit(onNextStep)}
        loading={isSubmitting}
      >
        <Typography variant='body-L' strong>
          {t('business:form.next')}
        </Typography>
      </Button>
    </div>
  );
};

export default ContactInfoFormItems;
