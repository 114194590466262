import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAnalytics } from '@wbk/analytics';
import { useGetEventDetail } from '@wbk/contentful/api';
import {
  BusinessSection,
  EventDetailSidebar,
  EventHeaderDetail,
  EventTitleDescription,
  SimilarEvents,
  SpotlightSectionAsync,
} from '@wbk/contentful';
import { Divider } from '@wbk/ui';
import { ALDANA_SLUG, WbkContactUs } from '@wbk/ticketing';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import { eventJsonLd } from '@/components/headers/seo/json-ld';
import Seo from '@/components/headers/seo';
import Error404 from '@/components/error/404';
import { parseContentfulToAnalyticsEvent } from '@/util/events';
import { DIRIYAH_CSS, DIRIYAH_SEASON_SLUG } from '@/constants/diriyah';
import AppSmartBanner from '@/components/app/SmartBanner';

const EventDetailPage = () => {
  const { slug, lang } = useParams<{ lang: Language; slug: string }>();
  const { data: event, isLoading } = useGetEventDetail({ lang, limit: 1, where: { slug } });
  const { viewItemEvent } = useAnalytics();

  useEffect(() => {
    // GA4 Event
    if (event) {
      viewItemEvent({
        event: parseContentfulToAnalyticsEvent(event),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!event) {
    return <Error404 />;
  }

  return (
    <>
      {event.season?.slug === DIRIYAH_SEASON_SLUG && <style>{DIRIYAH_CSS}</style>}
      <AppSmartBanner argument={`contentfulDetails/${event.sys.id}/${event.__typename}`} />

      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(eventJsonLd(event, `/${lang}/events/${slug}`)),
        }}
      />

      <Seo
        title={event.seo?.title || event.title}
        description={event.seo?.description}
        image={event.seo?.image?.url || event.image11.url}
      />

      <EventHeaderDetail event={event} />

      <section className='container flex flex-col-reverse gap-6 py-8 lg:flex-row'>
        <div className='grow overflow-hidden'>
          <EventTitleDescription event={event} />
          {event?.organizationSlug === ALDANA_SLUG && (
            <div className='space-y-4 lg:hidden'>
              <Divider />
              <WbkContactUs />
            </div>
          )}
        </div>
        <aside className='mx-auto w-full shrink-0 grow space-y-6 lg:max-w-md'>
          <EventDetailSidebar event={event} />
        </aside>
      </section>
      {event.season?.slug !== DIRIYAH_SEASON_SLUG && (
        <SpotlightSectionAsync
          id={
            event.location?.countryCode
              ? `webook_event_spotlight_${event.location.countryCode}`
              : undefined
          }
        />
      )}

      <SimilarEvents event={event} />

      <BusinessSection />
    </>
  );
};

export default EventDetailPage;
