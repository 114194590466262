import Layout from './components/layout';
import ScrollToTop from './components/common/ScrollToTop';
import ErrorBoundary from './components/error/ErrorBoundary';
import CookieConsent from './components/cookie/Bar';
import AppProviders from './context/providers';
import LocalizationProvider from './context/localization/provider';

type Props = {
  type?: LayoutType;
};

const AppLayout = ({ type }: Props) => {
  return (
    <ErrorBoundary>
      <LocalizationProvider>
        <AppProviders>
          <ScrollToTop />
          <Layout type={type} />
          <CookieConsent />
        </AppProviders>
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

export default AppLayout;
