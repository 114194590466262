import React from 'react';
import Error500 from '@/components/error/500';
import type { RouteObject } from 'react-router-dom';
const BookingLayout = React.lazy(() => import('@/components/profile/orders/BookingLayout'));
const FavoritesLayout = React.lazy(() => import('@/components/profile/favorites/FavoritesLayout'));
const ProfileInfoPage = React.lazy(() => import('@/pages/profile/info'));
const MyTravelers = React.lazy(() => import('@/pages/profile/travelers'));
const ProfileLayout = React.lazy(() => import('@/pages/profile/layout'));
const BookingHistoryPage = React.lazy(() => import('@/pages/profile/bookings'));
const OrderDetail = React.lazy(() => import('@/pages/profile/bookings/id'));
const ExperienceOrderDetail = React.lazy(() => import('@/pages/profile/bookings/experiences/id'));
const SeasonOrderDetail = React.lazy(() => import('@/pages/profile/bookings/seasons/id'));
const FlightsBookingHistoryPage = React.lazy(() => import('@/pages/profile/bookings/flights'));
const FlightOrderDetails = React.lazy(() => import('@/pages/profile/bookings/flights/id'));
const FavoritesPage = React.lazy(() => import('@/pages/profile/favorites'));
const InterestsPage = React.lazy(() => import('@/pages/profile/interests'));
const ProfileWalletPage = React.lazy(() => import('@/pages/profile/wallet'));

export const profileRoutes: RouteObject[] = [
  {
    path: 'profile',
    errorElement: <Error500 />,
    children: [
      {
        element: <ProfileLayout />,
        children: [
          {
            path: '',
            element: <ProfileInfoPage />,
          },
          {
            path: 'travelers',
            element: <MyTravelers />,
          },
          {
            path: 'bookings',
            element: <BookingLayout />,
            children: [
              {
                path: '',
                element: <BookingHistoryPage />,
              },
              {
                path: 'flights',
                element: <FlightsBookingHistoryPage />,
              },
            ],
          },
          {
            element: <FavoritesLayout />,
            children: [
              {
                path: 'favorites',
                element: <FavoritesPage />,
              },
              {
                path: 'interests',
                element: <InterestsPage />,
              },
            ],
          },
          {
            path: 'wallet',
            element: <ProfileWalletPage />,
          },
        ],
      },
      {
        path: 'bookings/:id',
        element: <OrderDetail />,
      },
      {
        path: 'bookings/flights/:id',
        element: <FlightOrderDetails />,
      },
      {
        path: 'bookings/experiences/:id',
        element: <ExperienceOrderDetail />,
      },
      {
        path: 'bookings/seasons/:id',
        element: <SeasonOrderDetail />,
      },
    ],
  },
];
