import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Divider, Popover, Typography } from '@wbk/ui';
import { LogoutModal, ProfileTop, useAuth } from '@wbk/auth';
import {
  ChevronIcon,
  FlatTicketIcon,
  MultiplePersonIcon,
  PersonRoundedIcon,
  WalletIcon,
} from '@wbk/svg';
import WalletBalance from '@/components/wallet/WalletBalance';

const LINKS = [
  {
    href: '/profile/bookings',
    title: 'my_bookings',
    Icon: FlatTicketIcon,
    value: 'bookings',
  },
  {
    href: '/profile/wallet',
    title: 'wallet.title',
    Icon: WalletIcon,
    value: 'wallet',
  },
  {
    href: '/profile',
    title: 'my_info',
    Icon: PersonRoundedIcon,
    value: 'info',
  },
  {
    href: '/profile/travelers',
    title: 'my_travelers',
    Icon: MultiplePersonIcon,
    value: 'travelers',
  },
];

const UserMenu = () => {
  const { lang } = useParams<{ lang: Language }>();
  const { pathname } = useLocation();
  const { t } = useTranslation(['common', 'profile']);
  const [isOpened, setIsOpened] = useState(false);
  const { user, fetchUser, loading } = useAuth({ autoFetch: isOpened });

  const close = () => setIsOpened(false);
  const menuLinks = useMemo(() => {
    const walletEnabled = user?.wallet_enabled === 'on';
    if (!walletEnabled) {
      return LINKS.filter((m) => m.value !== 'wallet');
    }

    return LINKS;
  }, [user?.wallet_enabled]);

  const subscription = user?.user_subscriptions?.find((item) => item?.status === 'active');

  return (
    <>
      <Popover
        align='end'
        sideOffset={14}
        className='w-screen max-w-96 border-none md:border [&>div]:space-y-4 [&>div]:p-4'
        open={isOpened}
        onOpenChange={setIsOpened}
        content={
          <>
            {loading ? (
              <UserMenuSkeleton />
            ) : !user ? (
              <div className='p-4'>
                <Button
                  as={Link}
                  to={`/${lang}/login?redirect=${pathname}`}
                  theme='white'
                  shape='outlined'
                  data-testid='header_user_menu_login_button'
                  data-location='header'
                  className='w-full !px-1.5 !py-1.5 md:!px-2.5'
                  role='menuitem'
                >
                  <Typography>{t('common:auth.login_signup')}</Typography>
                </Button>
              </div>
            ) : (
              <div className='space-y-2'>
                <ProfileTop subscription={subscription} />
                <ul className='space-y-XS'>
                  {menuLinks.map(({ Icon, ...link }) => {
                    const isWallet = link.value === 'wallet';

                    return (
                      <li key={link.href}>
                        <Button
                          as={Link}
                          data-testid={`header_user_menu_link_${link.href}`}
                          to={`/${lang}${link.href}`}
                          className='flex w-full items-center justify-between p-2'
                          data-menuitem={link.title}
                          data-location='user-menu'
                          onClick={close}
                          theme='action'
                          shape='text'
                        >
                          <div className='gap-S flex items-center'>
                            <Icon />
                            <Typography variant='label-L'>{t(`profile:${link.title}`)}</Typography>
                          </div>

                          {isWallet && <WalletBalance />}
                        </Button>
                      </li>
                    );
                  })}
                  <Divider className='border-paper-level-1' />
                  <li>
                    <LogoutModal
                      onClosed={close}
                      render={({ open }) => (
                        <Button
                          shape='text'
                          theme='action'
                          data-testid='header_user_menu_link_logout'
                          onClick={() => open()}
                          data-menuitem='logout'
                          className='flex w-full justify-start p-2'
                          data-location='user-menu'
                        >
                          <Typography variant='link-L'>{t('common:nav.logout')}</Typography>
                        </Button>
                      )}
                    />
                  </li>
                </ul>
              </div>
            )}
          </>
        }
      >
        <Button
          onClick={() => {
            if (!user && !loading) {
              fetchUser();
            }
          }}
          theme='white'
          shape='text'
          className='flex w-full items-center p-0'
        >
          {user ? (
            <div className='bg-primary font-gellix flex h-6 w-6 items-center justify-center rounded-full text-xs uppercase md:h-9 md:w-9 md:text-sm'>
              {user.first_name?.[0]}
              {user.last_name?.[0]}
            </div>
          ) : (
            <PersonRoundedIcon className='md:text-text-secondary h-6 w-6 md:h-9 md:w-9' />
          )}
          <ChevronIcon className='text-text-secondary mx-1 hidden h-2 w-2 rotate-90 md:block' />
        </Button>
      </Popover>
    </>
  );
};

const UserMenuSkeleton = () => {
  return (
    <div className='space-y-M'>
      <div className='border-paper-level-1 rounded-M relative flex animate-pulse items-center gap-5 overflow-hidden border p-5 pb-16'>
        <div className='bg-body-level-1 h-12 w-12 rounded-full' />
        <div className='bg-body-level-1 rounded-S h-7 w-1/2' />
        <div className='bg-body-level-1 border-paper-level-1 absolute bottom-0 left-0 h-12 w-full border-t' />
      </div>

      <div className='space-y-XS'>
        <div className='bg-body-level-1 rounded-S h-10 grow animate-pulse' />
        <div className='bg-body-level-1 rounded-S h-10 grow animate-pulse' />
        <div className='bg-body-level-1 rounded-S h-10 grow animate-pulse' />
      </div>
    </div>
  );
};

export default UserMenu;
