import React from 'react';
import SPLPage from '@/pages/spl';
import SPLTeamsPage from '@/pages/spl/teams';
import { PALM_WORLD_EVENT_SLUG } from '@/constants/plam-world';
import { HARRY_POTTER_SLUG } from '@/constants/harry-potter';
import { ART_SLUG } from '@/constants/art';
import { SAFF_SLUG } from '@/constants/saff';
import type { RouteObject } from 'react-router-dom';

const AhliFcPage = React.lazy(() => import('@/pages/events/ahli-fc'));
const IttihadPage = React.lazy(() => import('@/pages/events/ittihad'));
const JCSAPage = React.lazy(() => import('@/pages/events/jcsa'));
const JCSAEventGroupPage = React.lazy(() => import('@/pages/events/jcsa/group'));
const PageBySlug = React.lazy(() => import('@/pages/[slug]'));
const PalmWorldPage = React.lazy(() => import('@/pages/events/palm-world'));
const FormulaEPage = React.lazy(() => import('@/pages/events/formula'));
const HouseOfHypePage = React.lazy(() => import('@/pages/experiences/house-of-hype'));
const HarryPotterPage = React.lazy(() => import('@/pages/experiences/harry-potter'));
const ArtPage = React.lazy(() => import('@/pages/events/art'));
const ProgrammesPage = React.lazy(() => import('@/pages/events/art/group'));
const GalleriesPage = React.lazy(() => import('@/pages/events/art/galleries'));
const InformativePage = React.lazy(() => import('@/pages/events/art/informative'));
const ArtistsPage = React.lazy(() => import('@/pages/events/art/artists'));
const RamadanPage = React.lazy(() => import('@/pages/seasons/slug/ramadan/ramadan'));
const EWCPage = React.lazy(() => import('@/pages/events/ewc'));
const SAFFPage = React.lazy(() => import('@/pages/events/saff'));

export const customPagesRoutes: RouteObject[] = [
  { path: 'experiences/house-of-hype', element: <HouseOfHypePage /> },
  { path: `experiences/${HARRY_POTTER_SLUG}`, element: <HarryPotterPage /> },
  { path: 'events/ahli-fc', element: <AhliFcPage /> },
  { path: 'ittihad', element: <IttihadPage /> },
  { path: 'events/jcsa-homepage', element: <JCSAPage /> },
  {
    path: 'events/saudi-cup-taif-season',
    element: <JCSAEventGroupPage slug='saudi-cup-taif-season' />,
  },
  {
    path: 'events/riyadh-racing-season',
    element: <JCSAEventGroupPage slug='riyadh-racing-season' />,
  },
  { path: 'events/saudi-cup-season', element: <JCSAEventGroupPage slug='saudi-cup-season' /> },
  {
    path: 'events/the-world-awaits-saudi-cup-weekend-2026',
    element: <JCSAEventGroupPage slug='the-world-awaits-saudi-cup-weekend-2026' />,
  },
  { path: 'season/ramadan-season-2025', element: <RamadanPage /> },
  {
    path: 'spl',
    element: <SPLPage />,
  },
  {
    path: 'spl-teams',
    element: <SPLTeamsPage />,
  },
  {
    path: `events/${PALM_WORLD_EVENT_SLUG}`,
    element: <PalmWorldPage />,
  },
  {
    path: 'events/formula-e-25-3242',
    element: <FormulaEPage />,
  },
  {
    path: `events/${ART_SLUG}`,
    element: <ArtPage />,
  },
  {
    path: `events/${ART_SLUG}/:slug`,
    element: <ProgrammesPage />,
  },
  {
    path: `events/${ART_SLUG}/galleries`,
    element: <GalleriesPage />,
  },
  {
    path: `events/${ART_SLUG}/informative/:slug`,
    element: <InformativePage />,
  },
  {
    path: `events/${ART_SLUG}/artists`,
    element: <ArtistsPage />,
  },
  {
    path: 'events/esports-world-cup',
    element: <EWCPage />,
  },
  {
    path: `events/${SAFF_SLUG}`,
    element: <SAFFPage />,
  },
  // Must be last
  { path: ':slug', element: <PageBySlug /> },
];
