import React from 'react';
import { DIRIYAH_SEASON_SLUG } from '@/constants/diriyah';
import type { RouteObject } from 'react-router-dom';
const DiriyahPage = React.lazy(() => import('@/pages/diriyah-24-25/season'));

export const diriyahPagesRoutes: RouteObject[] = [
  {
    path: `season/${DIRIYAH_SEASON_SLUG}`,
    element: <DiriyahPage />,
  },
];
