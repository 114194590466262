import { useNavigate } from 'react-router-dom';
import { ChevronIcon } from '@wbk/svg';
import { Button, Typography } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { useBusiness } from '@wbk/api/hooks';
import { useFormContext } from 'react-hook-form';
import { scrollToTop } from '@wbk/utils';
import { BusinessFormValues } from '@/components/business/types';
import BusinessInfoFormItems from '@/components/business/BusinessInfo';
import ContactInfoFormItems from '@/components/business/ContactInfo';

const BusinessForm = () => {
  const { t } = useTranslation(['business', 'common']);
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useBusiness();
  const { setValue, watch, handleSubmit } = useFormContext<BusinessFormValues>();

  const step = watch('step') || 'contact_info';

  const onSubmit = async ({ phone, ...values }: BusinessFormValues) => {
    try {
      await mutateAsync({
        ...values,
        phone: `${phone.country?.dial_code}${phone.mobile}`,
        step: 'complete',
      });
      scrollToTop();
      setValue('step', 'success');
    } catch (error) {
      //
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          if (step === 'business_info') {
            setValue('step', 'contact_info');
          } else {
            navigate(-1);
          }
        }}
        shape='text'
        theme='transparent'
        className='p-px'
      >
        <ChevronIcon className='h-4 w-4 ltr:rotate-180' />
      </Button>

      <div className='space-y-1'>
        <Typography variant='heading-M'>{t('business:request_callback')}</Typography>
        <Typography color='text-secondary'>{t('business:request_callback_desc')}</Typography>
      </div>

      <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
        {step === 'contact_info' ? (
          <ContactInfoFormItems />
        ) : (
          <>
            <BusinessInfoFormItems />
            <Button type='submit' loading={isPending} className='w-full sm:w-auto'>
              <Typography variant='body-L' strong>
                {t('business:form.submit_request')}
              </Typography>
            </Button>
          </>
        )}
      </form>
    </>
  );
};

export default BusinessForm;
