import { useDeviceDetect } from '@wbk/hooks';
import { BottomSheet, Button, Modal, Switch, Typography } from '@wbk/ui';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { type CookieConsent, useCookieConsent } from '@wbk/analytics';
import { getItem, safeParseJson } from '@wbk/utils';
import useLocalization from '@/context/localization/useLocalization';

type FormValues = {
  analytics: boolean;
  advertisement: boolean;
};

type Props = {
  render?: React.ComponentProps<typeof Modal>['render'];
};

const CookieSettings = ({ render }: Props) => {
  const { t } = useTranslation('common');
  const { isMobile } = useDeviceDetect();

  const methods = useForm<FormValues>();

  return (
    <FormProvider {...methods}>
      {isMobile ? (
        <BottomSheet
          closeButton
          ignoreHistory
          className='z-[101] pb-24'
          sectionClassName='px-4'
          render={(props) => {
            if (render) {
              return render(props);
            }
            return (
              <Button shape='text' onClick={props.open}>
                <Typography variant='body-S'>{t('common:cookie.settings')}</Typography>
              </Button>
            );
          }}
          body={({ close }) => <Body onClose={close} />}
          stickyFooter={({ close }) => <Actions onClose={close} />}
        />
      ) : (
        <Modal
          closeButton
          ignoreHistory
          className='p-6 pb-24'
          zIndex={101}
          render={(props) => {
            if (render) {
              return render(props);
            }
            return (
              <Button shape='text' onClick={props.open}>
                <Typography variant='body-S'>{t('common:cookie.settings')}</Typography>
              </Button>
            );
          }}
          body={({ close }) => <Body onClose={close} />}
          stickyFooter={({ close }) => <Actions onClose={close} />}
        />
      )}
    </FormProvider>
  );
};

const Body = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('common');
  const { locale } = useLocalization();
  const { control, handleSubmit } = useFormContext<FormValues>();
  const { acceptConsent } = useCookieConsent();
  const savedValues = getItem('cookie_consent');
  const values = safeParseJson<CookieConsent>(savedValues || '');

  const onSubmit = (data: FormValues) => {
    onClose();
    acceptConsent({
      analytics_storage: data.analytics ? 'granted' : 'denied',
      ad_user_data: data.advertisement ? 'granted' : 'denied',
      ad_storage: data.advertisement ? 'granted' : 'denied',
      ad_personalization: data.advertisement ? 'granted' : 'denied',
    });
  };

  return (
    <form className='space-y-4' id='cookie_consent_settings' onSubmit={handleSubmit(onSubmit)}>
      <div className='space-y-2'>
        <Typography variant='heading-L'>{t('cookie.title')}</Typography>
        <Typography variant='body-S'>
          <Trans i18nKey='cookie.description' ns='common'>
            <Typography
              variant='link-S'
              color='primary'
              as={Link}
              to={`/${locale}/privacy`}
              onClick={onClose}
            ></Typography>
          </Trans>
        </Typography>
      </div>

      <div className='space-y-2'>
        <div className='flex items-center justify-between gap-2'>
          <Typography variant='heading-XS'>{t('cookie.necessary')}</Typography>
          <div className='flex shrink-0 gap-2'>
            <Typography variant='body-XS'>{t('cookie.always_active')}</Typography>
            <Switch checked disabled hideErrorSpace />
          </div>
        </div>
        <Typography variant='body-S'>{t('cookie.necessary_description')}</Typography>
      </div>

      <div className='space-y-2'>
        <div className='flex items-center justify-between gap-2'>
          <Controller
            control={control}
            name='analytics'
            defaultValue={values?.analytics_storage === 'granted'}
            render={({ field: { value, ...props } }) => (
              <Switch
                groupClassName='w-full [&>div]:flex-row-reverse [&>div]:justify-between'
                label={<Typography variant='heading-XS'>{t('cookie.analytics')}</Typography>}
                {...props}
                checked={value}
                hideErrorSpace
              />
            )}
          />
        </div>
        <Typography variant='body-S'>{t('cookie.analytics_description')}</Typography>
      </div>

      <div className='space-y-2'>
        <div className='flex items-center justify-between gap-2'>
          <Controller
            control={control}
            name='advertisement'
            defaultValue={values?.ad_storage === 'granted'}
            render={({ field: { value, ...props } }) => (
              <Switch
                groupClassName='w-full [&>div]:flex-row-reverse [&>div]:justify-between'
                label={<Typography variant='heading-XS'>{t('cookie.advertisement')}</Typography>}
                {...props}
                checked={value}
                hideErrorSpace
              />
            )}
          />
        </div>
        <Typography variant='body-S'>{t('cookie.advertisement_description')}</Typography>
      </div>
    </form>
  );
};

const Actions = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('common');
  const { reset } = useFormContext<FormValues>();
  const { acceptConsent } = useCookieConsent();

  return (
    <div className='flex justify-end gap-2 p-4'>
      <Button type='submit' form='cookie_consent_settings' shape='outlined'>
        <Typography>{t('common:cookie.save_settings')}</Typography>
      </Button>
      <Button
        type='button'
        onClick={() => {
          reset({ analytics: true, advertisement: true });
          acceptConsent();
          onClose();
        }}
      >
        <Typography>{t('common:cookie.accept_all')}</Typography>
      </Button>
    </div>
  );
};

export default CookieSettings;
