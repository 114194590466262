import { ContentfulButton } from '@wbk/contentful';
import { useLocation } from 'react-router-dom';

type Props = React.ComponentProps<typeof ContentfulButton>;

const NavLink = ({ link, className = '', ...rest }: Props) => {
  const { pathname } = useLocation();
  const isActive = pathname === link.href;

  return (
    <ContentfulButton
      link={link}
      className={`whitespace-nowrap px-2 transition ${link.shape === 'text' ? 'md:text-text-secondary' : 'w-full max-w-sm !justify-center gap-1 p-2'} ${link.shape === 'text' && isActive ? 'text-text font-bold' : ''} ${className}`}
      data-title={link.label}
      data-location='header'
      role='menuitem'
      {...rest}
    />
  );
};

export default NavLink;
