import { useMemo, useRef, useState } from 'react';
import { LocationIcon, WorldOutlinedIcon } from '@wbk/svg';
import { Button, ResponsiveMenu, Typography } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { ContentfulCountry, useGetCountries } from '@wbk/contentful/api';
import { isArabic } from '@wbk/utils';
import useLocalization from '@/context/localization/useLocalization';
import useFilters from '@/context/filters/useSearch';

type Props = {
  className?: string;
  render?: (props: { label: React.ReactNode; open?: () => void }) => React.ReactNode;
};

const ExploreLocationFilter = ({ className, render }: Props) => {
  const { locale, countryCode } = useLocalization();
  const { country, city, selectAndApply } = useFilters();
  const [search, setSearch] = useState('');
  const debounceRef = useRef<NodeJS.Timeout>(undefined);
  const { t } = useTranslation('common');
  const searchLang = isArabic(search) ? 'ar-SA' : 'en-US';
  const { data, isFetching, isPending, error } = useGetCountries(
    {
      lang: search ? searchLang : locale,
      where: {
        OR: [{ title_contains: search || null }, { cities: { title_contains: search || null } }],
      },
    },
    { staleTime: Infinity }
  );

  const handleSearch = (value: string) => {
    clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      setSearch(value);
    }, 500);
  };

  const flatList = useMemo(() => {
    const list: {
      value: string;
      text: React.ReactNode;
      label?: string;
      country?: ContentfulCountry;
      disabled?: boolean;
    }[] = [
      {
        value: '',
        text: t('common:nearby'),
        label: t('common:nearby'),
        country: undefined,
      },
      {
        value: 'anywhere',
        text: t('common:anywhere'),
        label: t('common:anywhere'),
        country: undefined,
      },
    ];

    for (const country of data || []) {
      const filteredCountries = country.title.toLowerCase().includes(search.toLowerCase());
      if (filteredCountries) {
        list.push({
          value: country.countryCode,
          label: country.title,
          text: country.title,
        });
      }

      const filteredCities = country.citiesCollection.items.filter(
        (city) =>
          city.title.toLowerCase().includes(search.toLowerCase()) ||
          country.title.toLowerCase().includes(search.toLowerCase())
      );

      const cities = filteredCities.map((city) => ({
        value: city.cityCode,
        label: city.title,
        text: city.title,
        country,
      }));
      if (cities.length) {
        list.push(...cities);
      }
    }

    return list;
  }, [data, search, t]);

  const options = useMemo(() => {
    if (isPending) {
      return [
        {
          ...flatList[0],
          text: (
            <div className='flex w-full items-center gap-2'>
              <WorldOutlinedIcon className='h-6 w-6 shrink-0 p-px' />
              <Typography className='line-clamp-1'>{t('common:anywhere')}</Typography>
            </div>
          ),
        },
      ].concat(
        [1, 2, 3].map((i) => ({
          value: i.toString(),
          label: '',
          country: undefined,
          text: (
            <div className='flex w-full items-center gap-2'>
              <LocationIcon className='h-6 w-6 shrink-0' />
              <div className='w-full space-y-2'>
                <div className='bg-body-dark/10 h-2 max-w-[50%] animate-pulse rounded-sm' />
                <div className='bg-body-dark/10 h-2 max-w-[60%] animate-pulse rounded-sm' />
              </div>
            </div>
          ),
        }))
      );
    }

    const isEmpty = flatList.length === 1;
    if (isEmpty) {
      return [
        {
          ...flatList[0],
          text: (
            <div className='flex items-center gap-2'>
              <WorldOutlinedIcon className='h-6 w-6 shrink-0 p-px' />
              <Typography className='line-clamp-1'>{t('common:anywhere')}</Typography>
            </div>
          ),
        },
      ];
    }

    return flatList.map((c) => {
      const isCity = !!c.country;
      const cityWord = c.label;
      const countryWord = c?.country?.title || c.label;

      return {
        ...c,
        label: c.label,
        text: (
          <div className='flex items-center gap-2'>
            {c.value === 'anywhere' ? (
              <WorldOutlinedIcon className='h-6 w-6 shrink-0 p-px' />
            ) : (
              <LocationIcon className='h-6 w-6 shrink-0' />
            )}
            <div>
              <Typography className='line-clamp-1'>{isCity ? cityWord : countryWord}</Typography>
              {!!isCity && (
                <Typography className='line-clamp-1' variant='body-XS' color='text-secondary'>
                  {countryWord}
                </Typography>
              )}
            </div>
          </div>
        ),
      };
    });
  }, [flatList, isPending, t]);

  return (
    <ResponsiveMenu
      render={({ open, selected }) => {
        const selectedLabel = selected?.label;
        const anywhereLabel = !selected ? t('common:anywhere') : '';
        const nearbyLabel = selected?.value === countryCode ? t('common:nearby') : '';
        const label = anywhereLabel || nearbyLabel || selectedLabel || t('common:nearby');

        if (render) {
          return render({ label, open });
        }

        return (
          <Button
            theme='white'
            shape='outlined'
            className={twMerge(
              'bg-body-level-1/20 border-paper w-full justify-start gap-2 border px-2',
              className
            )}
            onClick={() => {
              // Clear search on open
              setSearch('');
              open();
            }}
          >
            {!selected ? (
              <WorldOutlinedIcon className='h-6 w-6 shrink-0 p-px' />
            ) : (
              <LocationIcon className='h-6 w-6 shrink-0' />
            )}
            <div className='text-start'>
              <p className='text-text-secondary text-xs'>{t('common:destination')}</p>
              <p className='line-clamp-1'>{label}</p>
            </div>
          </Button>
        );
      }}
      search={{
        onChange: handleSearch,
        loading: isFetching,
        error: error?.message,
      }}
      options={options}
      value={!country ? 'anywhere' : city || country || ''}
      onChange={(option) => {
        const country = (option as { country?: ContentfulCountry })?.country;
        const isCity = !!country;
        selectAndApply([
          { key: 'city', value: isCity ? option.value : null },
          { key: 'country', value: country?.countryCode || option.value },
        ]);
      }}
      hideErrorSpace
      sheet={{
        ignoreHistory: true,
      }}
    />
  );
};

export default ExploreLocationFilter;
