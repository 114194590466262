import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePage } from '@wbk/contentful/api';
import { Button, ShareModal } from '@wbk/ui';
import Seo from '@/components/headers/seo';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import Error404 from '@/components/error/404';
import Hero from '@/components/spl/Hero';
import PageSection from '@/components/section';
import Tabs from '@/components/spl/Tabs';
import PaginatedPageWrapper from '@/components/page/Wrapper';

const SPLPage = () => {
  const { t } = useTranslation(['common', 'event']);
  const { lang } = useParams<{ lang: Language }>();
  const { data, isLoading, error, hasNextPage, fetchNextPage } = usePage({
    lang,
    limit: 1,
    where: { slug: 'spl' },
  });

  if (error) {
    throw error;
  }

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!data) {
    return <Error404 />;
  }

  return (
    <PaginatedPageWrapper hasNextPage={hasNextPage} fetchNextPage={fetchNextPage}>
      <Seo
        title={data.seo?.title || data.title}
        description={data.seo?.description}
        image={data.seo?.image?.url}
      />

      <Hero data={data} />

      <Tabs active='overview' />

      <section className='container mt-10 flex items-center justify-between gap-5'>
        <h2 className='text-3xl font-semibold md:text-4xl'>{t('event:upcoming_matches')}</h2>

        <ShareModal
          data={{
            title: data?.title,
            text: data?.title,
          }}
          render={({ share }) => {
            return (
              <Button theme='white' shape='outlined' className='space-x-2' onClick={share}>
                <img
                  alt={t('common:share_page')}
                  src='/icons/common/share.svg'
                  width={22}
                  height={22}
                />
                <span className='hidden sm:block'>{t('common:share_page')}</span>
              </Button>
            );
          }}
        />
      </section>

      {data?.sectionsCollection?.items?.map((section, idx) => (
        <PageSection key={section.__typename + idx} section={section} />
      ))}
    </PaginatedPageWrapper>
  );
};

export default SPLPage;
