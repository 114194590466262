import { StrokeCheckIcon, SuccessIcon } from '@wbk/svg';
import { Button, Divider, Typography } from '@wbk/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDate } from '@wbk/utils';
import useLocalization from '@/context/localization/useLocalization';

const Success = () => {
  const { locale } = useLocalization();
  const { t } = useTranslation(['business']);
  const { getValues } = useFormContext();
  const STEPS_COUNT = 4;

  const phone = `+${getValues('phone.country.dial_code')}${getValues('phone.mobile')}`;
  const email = getValues('email');

  return (
    <div className='space-y-6'>
      <StrokeCheckIcon className='text-success h-9 w-10' />
      <Typography as='h1' variant='heading-M'>
        {t('business:success.title')}
      </Typography>
      <Typography variant='body-L' color='text-tertiary'>
        {t('business:success.description', {
          phone,
          email,
        })}
      </Typography>
      <div className='flex flex-col gap-4'>
        <Button
          as={Link}
          to={`/${locale}`}
          theme='white'
          className='h-12 w-full sm:max-w-48'
          shape='outlined'
        >
          {t('business:success.back_to_home')}
        </Button>
        <Button
          as={Link}
          to={`/${locale}/business/help`}
          theme='white'
          className='h-12 w-full sm:max-w-56'
          shape='outlined'
        >
          {t('business:success.submit_another')}
        </Button>
      </div>
      <Divider type='zigzag' className='text-body-dark/20' />
      <Typography as='h3' variant='heading-XS'>
        {t('business:success.steps_title')}
      </Typography>

      <ul className='space-y-2'>
        {Array.from({ length: STEPS_COUNT }).map((_, index) => (
          <li key={index} className='flex gap-4'>
            <div className='flex flex-col items-center gap-2'>
              <div
                data-first={index === 0}
                className='border-body-dark data-[first=true]:bg-body-dark data-[first=true]:text-body-light group flex h-9 w-9 shrink-0 items-center justify-center rounded-full border'
              >
                <SuccessIcon className='hidden h-5 w-5 stroke-[2] group-data-[first=true]:block' />
                <Typography as='span' variant='body-S' className='group-data-[first=true]:hidden'>
                  {index + 1}
                </Typography>
              </div>

              {index < STEPS_COUNT - 1 && <div className='bg-body-dark/20 h-full w-px' />}
            </div>

            <div className='pb-3'>
              <Typography variant='label-L'>
                {t(`business:success.steps.${index + 1}.title`)}
              </Typography>
              <Typography variant='body-S' color='text-secondary'>
                {index === 0
                  ? formatDate(new Date(), locale, {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  : t(`business:success.steps.${index + 1}.description`)}
              </Typography>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Success;
