import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsProvider } from '@wbk/analytics';
import { AuthProvider } from '@wbk/auth';
import { Trans, useTranslation } from 'react-i18next';
import { WbkTicketingProvider } from '@wbk/ticketing';
import { SmcAdsProvider } from '@smc/ads';
import { UiProvider } from '@wbk/ui';
import { SubscriptionProvider } from '@wbk/subscription';
import { CurrencyProvider } from '@wbk/currency';
import { WbkAuctionProvider } from '@wbk/auction';
import { WbkConfigProvider } from '@wbk/config';
import { POSTER_1X1_FADED, POSTER_3X1_FADED } from '@/constants/fallbackLogo';
import { SUBSCRIPTIONS_SLUG } from '@/constants/subscriptions';
import { languages } from '@/i18n/config';
import useLocalization from './localization/useLocalization';
import { gtmTracker, wbkTracker } from '../../trackers.config';

type Props = {
  children: React.ReactNode;
};

const AppProviders = ({ children }: Props) => {
  const { locale, currencies, detectedCurrency, detectedCountryCode } = useLocalization();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <WbkConfigProvider
      i18n={{ useTranslation, Trans, languages }}
      geoLocation={{ countryCode: detectedCountryCode }}
      router={{
        Link,
        push: navigate,
        replace: (u: string) => navigate(u, { replace: true }),
        locale,
      }}
      appSource={process.env.VITE_PUBLIC_APP_SOURCE!}
      resell={{
        domain: process.env.VITE_PUBLIC_RESELL_DOMAIN,
      }}
    >
      <UiProvider imgFallbacks={{ '1x1': POSTER_1X1_FADED, '3x1': POSTER_3X1_FADED }}>
        <AnalyticsProvider trackers={[gtmTracker, wbkTracker]} currentLocation={pathname}>
          <CurrencyProvider
            detectedCurrency={detectedCurrency}
            currencies={currencies.map((c) => ({
              ...c,
              shortCode: c.short_code,
              symbol: c.currency_symbol || '',
              svgPath: c.svg_path,
              svgViewbox: c.svg_viewbox,
            }))}
          >
            <AuthProvider autoInit={false}>
              <SubscriptionProvider slug={SUBSCRIPTIONS_SLUG}>
                <WbkTicketingProvider
                  resell={{ domain: process.env.VITE_PUBLIC_RESELL_DOMAIN }}
                  seatsIO={{
                    workspace: process.env.VITE_PUBLIC_SEATIO_WORKSPACE_KEY,
                  }}
                >
                  <WbkAuctionProvider
                    endpoint={process.env.VITE_PUBLIC_SOCKET_HTTP_URL!}
                    socketEndpoint={process.env.VITE_PUBLIC_SOCKET_URL!}
                  >
                    <SmcAdsProvider>{children}</SmcAdsProvider>
                  </WbkAuctionProvider>
                </WbkTicketingProvider>
              </SubscriptionProvider>
            </AuthProvider>
          </CurrencyProvider>
        </AnalyticsProvider>
      </UiProvider>
    </WbkConfigProvider>
  );
};

export default AppProviders;
