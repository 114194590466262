import { Link } from 'react-router-dom';
import { WebsiteConfig } from '@wbk/contentful/api';
import { ImageWithFallback } from '@wbk/ui';
import SideMenu from '@/components/layout/header/SideMenu';
import LanguageCurrencySwitch from '@/components/common/LanguageCurrencySwitch';
import MobileSearch from '@/components/search/MobileSearch';
import useLocalization from '@/context/localization/useLocalization';
import UserProfileButton from './UserProfileButton';

type Props = {
  type?: LayoutType;
  config: WebsiteConfig;
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

const MobileHeader = ({ type, config, isMenuOpen, toggleMenu }: Props) => {
  const { locale } = useLocalization();
  const LogoComponent = type === 'booking' ? 'div' : Link;

  return (
    <nav className='flex items-center justify-between gap-2 p-3 py-1.5'>
      <div className='flex items-center gap-2'>
        {type !== 'booking' && (
          <SideMenu config={config} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        )}
        <LogoComponent
          data-testid='header_mobile_logo'
          to={`/${locale}`}
          className='logo-image relative flex items-center justify-center hover:opacity-90'
          data-location='header'
          aria-label='webook.com Logo'
        >
          <ImageWithFallback
            src={config.logo.url}
            data-small={type === 'booking'}
            className='max-h-8 w-full max-w-16 data-[small=true]:max-h-5 data-[small=true]:max-w-10'
            alt=''
          />
        </LogoComponent>
      </div>

      <div className='gap-M flex items-center'>
        {type !== 'booking' && <MobileSearch />}

        <div className='flex' role='menu'>
          <LanguageCurrencySwitch />
        </div>

        {type === 'main' && (
          <div className='relative shrink-0 text-sm' role='presentation'>
            <UserProfileButton />
          </div>
        )}
      </div>
    </nav>
  );
};

export default MobileHeader;
