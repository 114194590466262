import React from 'react';
import { type RouteObject, redirect } from 'react-router-dom';
import { createCookie } from '@wbk/utils';
import AboutPage from '@/pages/about';

const PrivacyPage = React.lazy(() => import('@/pages/privacy'));
const TermsAndConditionsPage = React.lazy(() => import('@/pages/terms'));
const BusinessPage = React.lazy(() => import('@/pages/business'));
const BusinessExperiencesPage = React.lazy(() => import('@/pages/business/experiences'));
const BusinessEventsPage = React.lazy(() => import('@/pages/business/events'));
const TwkLandPage = React.lazy(() => import('@/pages/twk'));
const StartupAcceleratorPage = React.lazy(() => import('@/pages/startup-accelerator'));
import BusinessSupportPage from '@/pages/business/[booking_type]';
const BusinessHelpPage = React.lazy(() => import('@/pages/business/help'));

export const infoRoutes: RouteObject[] = [
  {
    path: 'about',
    element: <AboutPage />,
  },
  {
    path: 'fun-startup-accelerator-fund',
    element: <StartupAcceleratorPage />,
  },
  {
    path: 'contact-us',
    loader: ({ params }) => {
      const lang = params.lang;
      const validLang = lang?.startsWith('ar') ? 'ar' : 'en-us';
      return redirect(`https://wbk.zendesk.com/hc/${validLang}`, { status: 302 });
    },
  },
  {
    path: 'advertise',
    element: <BusinessPage />,
  },
  {
    path: 'privacy',
    element: <PrivacyPage />,
  },
  {
    path: 'terms',
    element: <TermsAndConditionsPage />,
  },
  {
    path: 'faq',
    loader: ({ params }) => {
      const segments = Object.values(params || {}) || [];
      const firstSegment = segments?.[0] as Language;
      const lang = firstSegment.startsWith('ar') ? 'ar' : 'en-us';
      return redirect(`https://wbk.zendesk.com/hc/${lang}`, { status: 302 });
    },
  },
  {
    path: 'faqs',
    loader: ({ params }) => {
      const segments = Object.values(params || {}) || [];
      const firstSegment = segments?.[0] as Language;
      const lang = firstSegment.startsWith('ar') ? 'ar' : 'en-us';
      return redirect(`https://wbk.zendesk.com/hc/${lang}`, { status: 302 });
    },
  },
  {
    path: 'get-my-tickets',
    loader: ({ params }) => {
      const segments = Object.values(params || {}) || [];
      const firstSegment = segments?.[0] as Language;
      const lang = firstSegment.startsWith('ar') ? 'ar' : 'en-us';
      return redirect(`https://wbk.zendesk.com/hc/${lang}`, { status: 302 });
    },
  },
  {
    path: 'business',
    element: <BusinessPage />,
  },
  {
    path: 'business/experiences',
    element: <BusinessExperiencesPage />,
  },
  {
    path: 'business/events',
    element: <BusinessEventsPage />,
  },
  {
    path: 'business/help',
    element: <BusinessHelpPage />,
  },
  {
    path: 'business/:booking_type',
    element: <BusinessSupportPage />,
  },
  {
    path: 'twk',
    loader: ({ params, request }) => {
      createCookie({
        name: 'twk',
        value: 'true',
        expiration: 7,
      });
      const url = new URL(request.url);
      const searchParams = url.searchParams;
      const lang = searchParams.get('lang');
      const twkLang = lang === 'ar' ? 'ar' : 'en';

      const internalLang = params.lang;

      if (internalLang !== twkLang) {
        return redirect(`/${twkLang}/twk?${searchParams.toString()}`);
      }

      return null;
    },
    element: <TwkLandPage />,
  },
];
