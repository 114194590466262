import AuthLayout from '@/pages/auth/layout';
import LoginPage from '@/pages/auth/login';
import SignupPage from '@/pages/auth/signup';
import type { RouteObject } from 'react-router-dom';

export const authRoutes: RouteObject[] = [
  {
    path: 'login',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <LoginPage />,
      },
    ],
  },
  {
    path: 'signup',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SignupPage />,
      },
    ],
  },
];
